<template>
    <div class="shipping-units-summary">
        <div class="shipping-units-summary-wrapper">
            <div class="shipping-units-summary__title">Consignment Tally</div>
            <div class="shipping-units-summary__value"><span>Quantity:</span> <span>{{ totalItems }}</span></div>
            <div class="shipping-units-summary__value"><span>Volume:</span> <span>{{ totalVolume | dec3 }} m<sup>3</sup></span></div>
            <div class="shipping-units-summary__value"><span>Weight:</span> <span>{{ totalWeight | dec2 }} kg</span></div>
            <div class="shipping-units-summary__value" :class="{'text-danger': hasDgs}">
                <span v-if="!hasDgs">No </span><span v-else class="icon fa fa-exclamation-triangle"></span>DGs
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ShippingUnitsSummary',
    props: ['shippingUnits'],
    data () {
      return {
        items: this.shippingUnits ? this.shippingUnits : [],
        focusedItem: null
      }
    },
    created () {
      window['eventBus'].$on('ShippingUnitsUpdated', (value) => {
        this.$set(this, 'items', value.model)
      })
    },
    computed: {
      totalItems () {
        return this.items.reduce((current, item) => current + parseInt(item.qty), 0)
      },
      totalVolume () {
        return this.items.reduce((current, item) => current + (item.qty * item.length * item.width * item.height), 0) / 1000000
      },
      totalWeight () {
        return this.items.reduce((current, item) => current + (item.qty * item.weight), 0)
      },
      hasDgs () {
        return this.items.reduce((current, item) => current || item.dangerousGoods.length > 0, false)
      }
    },

    filters: {
      dec2 (val) {
        return val.toFixed(2)
      },

      dec3 (val) {
        return val.toFixed(3)
      }
    }
  }
</script>

<style scoped>
    .shipping-units-summary {
        font-size: 0.925em;
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
    }

    .shipping-units-summary-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 1em;
    }

    .shipping-units-summary-wrapper:first-child,
    .shipping-units-summary-wrapper:last-child {
        border-radius: 5px;
    }

    .shipping-units-summary__value > * {
        margin-right: 0.25em;
    }

    .shipping-units-summary-wrapper > * {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 130px;
        height: 100%;
        padding: 0.65em 1em;
        border: 1px solid #e0e0e0;
    }

    .shipping-units-summary__title {
        font-weight: bold;

    }

    .icon {
        line-height: 1.5em;
    }
</style>