$(function () {
  // actions
  const getAccountAliasOptions = () => new Promise((resolve, reject) => {
    $.ajax({url: '/account/GetAliasAccountList', type: 'POST', dataType: 'json'})
      .done(accountList => {
        if (accountList && $.isArray(accountList)) {
          const options = accountList.map(account => {
            const option = document.createElement('option')
            option.value = account.AccountID
            option.text = account.AccountName

            return option
          })

          resolve(options)
        } else {
          reject()
        }
      })
  })

  const startLoading = function () {
    $('#account-alias').children().hide()
    $('#account-alias').addClass('fa fa-spin fa-spinner')
  }

  const stopLoading = function () {
    $('#account-alias').children().show()
    $('#account-alias').removeClass('fa fa-spin fa-spinner')
  }

  const openAlias = function () {
    const $aliasInput = $('#alias-input')

    $aliasInput.combobox()

    // start loading
    startLoading()
    getAccountAliasOptions()
      .then(options => {
        $aliasInput.html(options)

        const currentAccountId = $aliasInput.data('account')
        $aliasInput.find(`[value=${currentAccountId}]`).attr('selected', 'selected')
        $aliasInput.trigger('change')

        $('#account-info-wrapper').hide(300)
        $('#alias-wrapper').show(300)
      })
      .finally(() => {
        stopLoading()
      })
  }

  const aliasToAccount = function (e) {
    const $selection = $(this).find('#alias-input option:selected')

    $.ajax({
      type: 'POST',
      url: Enodo.rootUrl + '/Account/ChangeAlias',
      data: {accountID: $selection.val(), accountName: $selection.text()},
      success: function () {
        location.reload()
      },
      error: function (request, status, error) {
        $.okDialog(error)
      }
    })

    return false
  }

  const closeAlias = () => {
    $('#account-info-wrapper').show(300)
    $('#alias-wrapper').hide(300)
  }

  // events
  $('#account-alias').click(openAlias)
  $('#close-alias-form').click(closeAlias)
  $('#alias-form').on('submit', aliasToAccount)
})