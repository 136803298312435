import './libs'

/* App Namespace obj */
window.Enodo = {
    rootUrl: typeof (rootUrl) === 'undefined' ? "" : rootUrl,
    debug: true,

    Views: {},
    Modules: {},
    Models: {},
    Repositories: {},

    /* Functions */
    log: function (severity, message) {
        if (this.debug) {
            console[(severity === 1) ? 'log' : (severity === 2) ? 'warn' : 'error'](message);
        } else {
            // send to the server
        }
    },
    processStart: function () {
        var $progressBar = $('.progress');
        $('html, body').addClass('processing');
        $progressBar.show();
        $("button[data-process-btn]").attr("disabled", 'disabled');
    },
    processStop: function () {
        var $progressBar = $('.progress');
        $('html, body').removeClass('processing');
        $progressBar.hide();
        $("button[data-process-btn]").removeAttr("disabled");
    }
};
