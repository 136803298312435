<template>
    <div class="">
        <div class="my-products__options row">
            <div class="col-sm-6">
                <div class="input-group">
                    <b class="right-margin-1">Shipping Units:</b>
                    <label for="my-products__active" class="right-margin-1">
                        <input id="my-products__active" type="radio" name="active" :value="true"
                               v-model="showActive">Active</label>
                    <label for="my-products__inactive">
                        <input id="my-products__inactive" type="radio" name="active" :value="false"
                               v-model="showActive">Inactive</label>
                </div>
            </div>
            <div class="col col-sm-6 my-products__actions">
                <a href="#" title="Add New Shipping Unit"
                   @click="openModal()"><span class="btn btn-primary btn-sm">Add Shipping Unit</span></a>
            </div>
        </div>
        <br>
        <ProductList :can-edit="showActive"
                     :products="items"
                     :loading-products="loadingProducts"
                     :key="tableKey"
                     @editProduct="openModal($event)"
                     @activateDeactivate="activateDeactivate"></ProductList>

        <ProductsUpsertModal ref="ProductsUpsertModal"
                             @saved="savedHandler"></ProductsUpsertModal>    
    </div>
</template>

<script>
  import ProductsUpsertModal from './ProductsUpsertModal'
  import ProductList from '../components/ProductList'
  import saveProduct from '../myProductsApiService'

  export default {
    name: 'MyProducts',
    components: {ProductsUpsertModal, ProductList},

    data: function () {
      return {
        getProductsUrl: '/products/list',
        showActive: true,
        items: [],
        loadingProducts: false,
        tableKey: 0
      }
    },

    watch: {
      showActive () {
        this.refreshMyProductsGridView()
      }
    },

    mounted () {
      this.refreshMyProductsGridView()
    },

    methods: {
      openModal (item = null) {
        this.$refs.ProductsUpsertModal.open(item)
      },

      refreshMyProductsGridView () {
        this.loadingProducts = true

        $.ajax({
          type: 'GET',
          url: this.getProductsUrl,
          data: {active: this.showActive},
          dataType: 'json',
          cache: false,
          success: (data) => {
            this.loadingProducts = false
            this.$set(this, 'items', data)
            this.$nextTick(() => {
              this.tableKey += 1
            })
          },
          error: function (request, status, error) {
            $.okDialog(error)
          }
        })
      },

      savedHandler () {
        this.refreshMyProductsGridView()
      },

      activateDeactivate (product) {		
        product.IsActive = !product.IsActive
        product.PackagingType = product.PackagingType ? product.PackagingType.Code : ''
        const activeDeactive = product.IsActive ? 'activate' : 'deactivate'
        $.confirmDialog('Do you wish to ' + activeDeactive + ' the selected shipping unit?', null, 'info', () => {
          saveProduct(product)
            .then(() => {
              window['swal']({
                title: 'Done',
                type: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1000
              })
              this.refreshMyProductsGridView()
            })
            .catch(() => {
              $.okDialog(error)
            })
        })
      }
    }
  }
</script>

<style>
    .right-margin-1 {
        margin-right: 1em;
    }

    .my-products__actions {
        text-align: right;
    }
</style>