<template>
    <div class="form-group" :class="{'has-error': error}">
        <label class="control-label" :for="identifier">{{ label }}</label>
        <input :id="identifier" class="form-control"
               :type="type"
               :readOnly="readOnly"
               :tabindex="tabIndex"
               :placeholder="placeholderValue"
               :value="value"
               :required="required"
               @input="$emit('input', $event.target.value)">
        <slot name="errors" v-if="error"></slot>
    </div>
</template>

<script>
  export default {
    name: 'OFFormControl',
    props: {
      label: String,
      value: {},
      type: {type: String, default: 'text'},
      readOnly: {type: Boolean, default: false},
      placeholder: {required: false, type: String},
      id: String,
      required: {type: Boolean, default: false},
      error: {default: false, type: Boolean}
    },
    computed: {
      identifier () {
        return this.id || 'of_control_' + this.label.replace(/\W/g, '')
      },
      placeholderValue () {
        return this.placeholder || this.label
      },
      tabIndex () {
        return this.readOnly ? -1 : 0
      }
    }
  }
</script>

<style scoped>

</style>