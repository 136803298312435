<template>
    <div class="product-list">
        <div class="oneflo-loader" v-show="loadingProducts">
            <i class="fa fa-spin fa-spinner fa-4x"></i>
        </div>
        <div class="my-products__table table-responsive">
            <table id="dataTableConsignmentItems" class="table table-hover dataTable table-condensed" width="100%">
                <thead>
                <tr>
                    <th>Code/Type</th>
                    <th>Name</th>
                    <th data-class-name="td-center">Packaging</th>
                    <th>Weight</th>
                    <th>Height</th>
                    <th>Length</th>
                    <th>Width</th>
                    <th>Volume</th>
                    <th data-orderable="false">DG</th>
                    <th data-orderable="false"></th>
                    <th data-orderable="false"></th>
                    <th data-orderable="false"></th>
                    <th class="hidden"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="_item in products">
                    <td>{{_item.Code}}</td>
                    <td>{{_item.Name}}</td>
					<td><span v-if="_item.PackagingType" :data-code="_item.PackagingType.Code">{{_item.PackagingType.Description}}</span><span v-else>-</span></td>
                    <td class="rtl">{{_item.Weight | dec1}}</td>
                    <td class="rtl">{{_item.Height | dec2}}</td>
                    <td class="rtl">{{_item.Length | dec2}}</td>
                    <td class="rtl">{{_item.Width | dec2}}</td>
                    <td class="rtl">{{_item.Volume | dec3}}</td>
                    <td>
                        <i class='fa' :class="{'fa-check-square-o': _item.DangerousGoods.length}"></i>
                    </td>
                    <td><a v-if="canEdit" href="#" rel="adddg" :id="_item.id" title="Add Dangerous Goods"><i class="fa fa-bolt"></i></a></td>
                    <td>
                        <a href="#" rel="activate"
                           :title='_item.IsActive ? "Deactivate Shipping Unit" : "Activate Shipping Unit"'
                           :productid="_item.ProductID"
                           @click="$emit('activateDeactivate', _item)">
                            <i class="fa"
                               :class="{'fa-arrow-circle-o-right': _item.IsActive, 'fa-check-circle-o': !_item.IsActive}"></i>
                        </a>
                    </td>
                    <td><a v-if="canEdit" href="#" title="Edit Shipping Unit" data-toggle="modal" data-target="#modalWindow"
                           @click="$emit('editProduct', _item)"
                           :id="_item.id"><i class="fa fa-edit"></i></a>
                    </td>
                    <td class="hidden"><input rel="hdnSelectedDgs" type="hidden" :value="JSON.stringify(_item.DangerousGoods)"/></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ProductList',
    props: {
      canEdit: {default: true, type: Boolean},
      products: {required: true},
      loadingProducts: {required: false, default: false, type: Boolean}
    },

    mounted() {
      $('#dataTableConsignmentItems').DataTable()
    },

    filters: {
		dec1(val) {
        return val.toFixed(1)
      },

      dec2 (val) {
        return val.toFixed(2)
      },

      dec3 (val) {
        return val.toFixed(3)
      }
    }
  }
</script>

<style scoped>
    .product-list {
        position: relative;
    }

    .oneflo-loader {
        position: absolute;
        top: -6em;
        left: 0;
    }
    
    .td-center {
        text-align: center;
    }

    .my-products__table {
        margin-top: 2em;
    }
</style>