<template>
    <div class="">
        <div class="shipping-units-grid__table">
            <!-- grid header -->
            <div class="shipping-units-grid__table__header"
                 :style="{'grid-column': 1, 'grid-row': 1}">Reference
                <small>(optional)</small>
            </div>

            <div class="shipping-units-grid__table__header"
                 :style="{'grid-column': 2, 'grid-row': 1}">Code
            </div>
            <div class="shipping-units-grid__table__header"
                 :style="{'grid-column': 3, 'grid-row': 1}">Description
            </div>
            <div class="shipping-units-grid__table__header"
                 :style="{'grid-column': 4, 'grid-row': 1}">Packaging
            </div>
            <div class="shipping-units-grid__table__header text-center"
                 :style="{'grid-column': 5, 'grid-row': 1}">Items qty
            </div>
            <div class="shipping-units-grid__table__header text-center"
                 :style="{'grid-column': 6, 'grid-row': 1}">L<br>(cm)
            </div>
            <div class="shipping-units-grid__table__header text-center"
                 :style="{'grid-column': 7, 'grid-row': 1}">W<br>(cm)
            </div>
            <div class="shipping-units-grid__table__header text-center"
                 :style="{'grid-column': 8, 'grid-row': 1}">H<br>(cm)
            </div>
            <div class="shipping-units-grid__table__header is-readonly text-center"
                 :style="{'grid-column': 9, 'grid-row': 1}">Vol<br>(m<sup>3</sup>)
            </div>
            <div class="shipping-units-grid__table__header"
                 style="text-align: center;"
                 :style="{'grid-column': 10, 'grid-row': 1}">Weight <br>(kg)
            </div>
            <div class="shipping-units-grid__table__header text-center"
                 :style="{'grid-column': 11, 'grid-row': 1}">Dangerous Goods
            </div>
            <div class="is-borderless"
                 :style="{'grid-column': 12, 'grid-row': 1}"><!--Delete--></div>
            <!-- close grid header -->

            <!-- grid body. items -->
            <template v-for="(_item, _index) in $v.items.$each.$iter">
                <input title="Reference" type="text"
                       ref="reference"
                       :style="{'grid-column': 1, 'grid-row': _index + 2}"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.reference.$error}"
                       @focus="focusedItem = _item" @blur="focusedItem = null"
                       v-model.trim="_item.reference.$model">
                <div class="is-paddingless" title="Code"
                     :class="{'has-error': _item.code.$error}"
                     :style="{'grid-column': 2, 'grid-row': _index + 2}">
                    <v-select placeholder="Search"
                              label="code"
                              :value="_item.code.$model"
                              :filterable="false"
                              :selectOnTab="true"
                              :options="codeOptions"
                              :clearable="false"
                              :components="{OpenIndicator: null}"
                              taggable
                              :tabindex="0"
                              @search="onCodeSearch"
                              @input="onCodeSelect(_item.$model, $event)">
                        <template slot="no-options">Type to search codes...</template>
                        <template slot="option" slot-scope="option">
                            <div class="">
                                <b>{{ option.code }}</b>
                                <small v-if="option.name">{{ option.name }}</small>
                                <small v-if="option.length">| {{ option.length }} x {{ option.width }} x {{ option.height }} cm</small>
                                <small v-if="option.name">| {{ option.weight }} kg</small>
                            </div>
                        </template>
                        <!--<template slot="selected-option" slot-scope="option">{{ option.code }}</template>-->
                    </v-select>
                </div>
                <input title="Description" type="text"
                       ref="descriptionInput"
                       :style="{'grid-column': 3, 'grid-row': _index + 2}"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.description.$error}"
                       @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                       v-model.trim="_item.description.$model">
                <div class="is-paddingless"
                     :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.packagingType.$error}"
                     :style="{'grid-column': 4, 'grid-row': _index + 2}">
                    <v-select :options="packagingTypes"
                              :components="{OpenIndicator: null}"
                              :key="packagingTypeKey"
                              label="description"
                              :selectOnTab="true"
                              :reduce="packaging => packaging.code"
                              :clearable="false"
                              ref="packaging"
                              :tabindex="0"
                              :value="_item.packagingType.$model"
                              @input="onPackagingSelect(_item.$model, $event)">
                    </v-select>
                </div>
                <input title="Quantity" type="number" class="input-number"
                       ref="itemQtyInput"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.qty.$error}"
                       @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                       v-model.trim="_item.qty.$model" @input="_item.$touch()"
                       :style="{'grid-column': 5, 'grid-row': _index + 2}">
                <input title="Length" type="text" class="input-number"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.length.$error}"
                       @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                       v-model.trim="_item.length.$model"
                       :style="{'grid-column': 6, 'grid-row': _index + 2}">
                <input title="Width" type="text" class="input-number"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.width.$error}"
                       @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                       v-model.trim="_item.width.$model"
                       :style="{'grid-column': 7, 'grid-row': _index + 2}">
                <input title="Height" type="text" class="input-number"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.height.$error}"
                       @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                       v-model.trim="_item.height.$model"
                       :style="{'grid-column': 8, 'grid-row': _index + 2}">
                <div class="is-right is-readonly" tabindex="-1"
                     :style="{'grid-column': 9, 'grid-row': _index + 2}">
                    <span>{{ itemVolume(_item.$model) | dec3 }}</span></div>
                <input title="Weight" type="number" step="any" class="input-number"
                       :class="{'is-row-selected': _item.$model == focusedItem, 'has-error': _item.weight.$error}"
                       @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                       v-model.trim="_item.weight.$model"
                       :style="{'grid-column': 10, 'grid-row': _index + 2}">
                <button title="Add DGs" type="button" class="btn btn-sm btn-default"
                        :class="{'is-row-selected': _item.$model == focusedItem}"
                        @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                        @click="AddDgs(_index)"
                        :style="{'grid-column': 11, 'grid-row': _index + 2}">
                    <span v-if="_item.dangerousGoods.$model.length"><span class="fa fa-check"></span> DGs</span>
                    <span v-else>Add DGs</span></button>
                <button title="Remove Item" type="button" class="btn-zero is-borderless"
                        @focus="focusedItem = _item.$model" @blur="focusedItem = null"
                        :class="{'is-row-selected': _item.$model == focusedItem}"
                        @click="removeItem(_index)"
                        :style="{'grid-column': 12, 'grid-row': _index + 2}">
                    <span class="icon-icons-delete"></span></button>
                <ShippingUnitsGridDGsModal :key="dgsModalKey" ref="dgsModal" v-model="_item.dangerousGoods.$model"></ShippingUnitsGridDGsModal>
            </template>
            <!-- close grid body -->
        </div>

        <div class="shipping-units-grid__actions">
            <div v-if="focusedItem" class="shipping-units-grid__actions__details">
                <span class="">Selected Item</span>
                <span class="">-</span>
                <span class=""><b>Total Volume</b></span>
                <span class="">{{ totalItemVolume(focusedItem) | dec3 }} m<sup>3</sup></span>
                <span class="">-</span>
                <span class=""><b>Total Weight</b></span>
                <span class="">{{ totalItemWeight(focusedItem) | dec2 }} kg</span>
                <span></span>
            </div>
            <button type="button" class="shipping-units-grid__actions__add-items btn btn-zero btn-sm"
                    @click="addItem">+ Shipping Items
            </button>
        </div>
    </div>
</template>

<script>
  import { packagingTypeClient, searchCodes } from '../../../services/api-client-service'
  import ShippingUnitsGridDGsModal from './ShippingUnitsGridDGsModal'
  import { decimal, integer, maxLength, minLength, minValue, required, requiredIf } from 'vuelidate/lib/validators'
  import OFPackagingInput from '../../../components/OFPackagingInput'
  import greater from '../../../validators/greater'
  import _ from 'lodash'

  function isIEOrEdge () {
    const ua = navigator.userAgent
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || ua.indexOf('Edge/') > -1
  }

  const createItem = (su = {}) => ({
    id: su.Id || 0,
    code: su.Code ? su.Code : su.ConsignmentProduct ? su.ConsignmentProduct.Code : null,
    productId: su.ProductID || 0,
    reference: su.Reference || '',
    description: su.Description || '',
    qty: su.NoItems || su.Items || 1,
    length: su.Length || null,
    width: su.Width || null,
    height: su.Height || null,
    weight: su.Weight || null,
    packagingType: su.PackagingType || null,
    dangerousGoods: su.ConsignmentProduct ? su.ConsignmentProduct.DangerousGoods : []
  })

  export default {
    name: 'ShippingUnitsGrid',
    components: {OFPackagingInput, ShippingUnitsGridDGsModal},
    props: ['shippingUnits'],
    isOrderLookup: false,
    data () {
      return {
        items: this.shippingUnits ? this.shippingUnits.map(createItem) : [],
        focusedItem: null,
        codeOptions: [],
        code: null,
        packagingTypes: [],
        packagingTypeKey: 0,
        dgsModalKey: 0
      }
    },
    validations: {
      items: {
        required,
        minLength: minLength(1),
        $each: {
          reference: {maxLength: maxLength(20)},
          code: {required},
          description: {},
          qty: {required, integer, minValue: minValue(1)},
          length: {required, decimal, positive: greater(0)},
          width: {required, decimal, positive: greater(0)},
          height: {required, decimal, positive: greater(0)},
          weight: {required, decimal, positive: greater(0)},
          packagingType: {required: requiredIf(function () { return !this.isOrderLookup })},
          dangerousGoods: {}
        }
      }
    },

    created () {
      packagingTypeClient.fetch()
        .then(packagingTypes => {
          this.packagingTypes = packagingTypes

          // we need to rerender the select to correctly setup the initial value if any
          this.packagingTypeKey += 1
        })
      /* Events */
      window['eventBus'].$on('createConsignmentValidate', () => this.$v.$touch())
      window.eventBus.$on('setShippingUnits', (SUs) => {
        this.$set(this, 'items', SUs.map(createItem))
        if (SUs.length <= 0)
          this.addItem({autofocus: false})
        this.isOrderLookup = true

        // we need to rerender the select to correctly setup the initial value if any
        this.packagingTypeKey += 1

        this.pushShippingUnits()
      })
      window.eventBus.$on('clearConsignmentItems', () => {
        this.items = []
        this.addItem({autofocus: false})
        this.packagingTypeKey += 1
        this.dgsModalKey += 1
        this.$v.$reset()
      })
    },

    mounted () {
      if (this.items.length == 0) {
        this.addItem({autofocus: false})
      }

      setTimeout(() => {
        this.pushShippingUnits()
      }, 500)
    },

    watch: {
      items: {
        deep: true,
        handler () {
          this.pushShippingUnits()
        }
      }
    },

    methods: {
      pushShippingUnits () {
        window['eventBus'].$emit('ShippingUnitsUpdated', {
          model: this.$v.items.$model,
          isValid: !this.$v.items.$invalid
        })
      },

      itemVolume (item) {
        return item.length * item.width * item.height / 1000000
      },
      totalItemVolume (item) {
        const totalVolume = item.qty * item.length * item.width * item.height / 1000000

        return isNaN(totalVolume) ? 0 : totalVolume
      },
      totalItemWeight (item) {
        const totalWeight = item.weight * item.qty

        return isNaN(totalWeight) ? 0 : totalWeight
      },

      addItem ({autofocus = true}) {
        this.$v.$touch()

        if (this.$v.$invalid && this.items.length !== 0) {
          this.$refs.reference[this.$refs.reference.length - 1].focus()
          return
        }

        this.items.push(createItem())
        if (autofocus) {
          this.$nextTick(() => {
            this.$refs.reference[this.$refs.reference.length - 1].focus()
          })
        }
      },

      removeItem (index) {
        this.items.splice(index, 1)
      },

      onCodeSearch: _.debounce(function (search, loading) {
        if (search.length >= 2) {
          loading(true)
          searchCodes(search)
            .then((codes) => {
              this.codeOptions = codes
              loading(false)
            })
        }
        loading(false)
      }, 350),

      onCodeSelect (item, option) {
        if (option === item.code) return

        if (!option || !option.name) { // if custom code is selected
          if (option.code) item.code = option.code // if options the component will return an object with the code key
          else item.code = option // if no options it will return string

          if (isIEOrEdge()) {
            this.$nextTick(() => {
              setTimeout(() => { // necessary as tab event cannot be prevented
                this.$refs.descriptionInput[this.items.indexOf(item)].focus()
              }, 100)
            })
          }

          return
        }

        item.code = option.code
        item.description = option.name
        item.length = option.length
        item.width = option.width
        item.height = option.height
        item.weight = option.weight
        item.productId = option.id

        item.dangerousGoods = option.dangerousGoods.map(dg => ({              
                ID: dg.Id,
                DGID: dg.DGId,
                UNCode: dg.UNCode,
                PSN: dg.PSN,
                Name: dg.Name,
                Receptacle: dg.Receptacle,
                Group: dg.Group,
                Class: dg.Class,
                SubRisk: dg.SubRisk,
                Quantity: dg.Quantity,
                AggQty: dg.AggQty,
                Units: dg.Units
          }))

        if ((!option.packagingType || !option.packagingType.Code)) { // code with no packaging
          this.$nextTick(() => {
            setTimeout(() => { // necessary as tab event cannot be prevented
              this.$refs.packaging[this.items.indexOf(item)].$el.querySelector('input').focus()
            }, 100)
          })
          return
        }

        item.packagingType = option.packagingType.Code
        // we need to rerender the select to correctly setup the packaging type
        this.packagingTypeKey += 1

        this.$nextTick(() => {
          setTimeout(() => { // necessary as tab event cannot be prevented
            this.$refs.itemQtyInput[this.items.indexOf(item)].focus()
          }, 100)
        })
      },

      onPackagingSelect (item, option) {
        item.packagingType = option

        if (isIEOrEdge()) {
          this.$nextTick(() => {
            setTimeout(() => { // necessary as tab event cannot be prevented
              this.$refs.itemQtyInput[this.items.indexOf(item)].focus()
            }, 100)
          })
        }
      },

      AddDgs (index) {
        this.$refs.dgsModal[index].open()
      }
    },

    filters: {
      dec2 (val) {
        return val.toFixed(2)
      },

      dec3 (val) {
        return val.toFixed(3)
      }
    }
  }
</script>

<style scoped>
    /*  */
    .shipping-units-grid__table {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: /* ref*/ 2fr /* code */ 3fr /* description */ 3fr /* Palletised */ 2fr /* dimensions */ minmax(55px, 6%) minmax(55px, 6%) minmax(55px, 6%) minmax(55px, 6%) /* weight */ minmax(55px, 6%) /*item*/ minmax(55px, 6%) /* Add DGs */ 70px /* Remove btn*/ 40px;
        grid-template-columns: /* ref*/ 2fr /* code */ 3fr /* description */ 3fr /* Palletised */ 2fr /* dimensions */ minmax(55px, 6%) minmax(55px, 6%) minmax(55px, 6%) minmax(55px, 6%) /* weight */ minmax(55px, 6%) /*item*/ minmax(55px, 6%) /* Add DGs */ 70px /* Remove btn*/ 40px;
        grid-gap: 0px;
        margin-bottom: 0.5em;
    }

    .shipping-units-grid__table > * {
        width: 100%;
        height: 100%;
        min-height: 30px;
        border: none;
        border-left: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: 3px;
    }

    .shipping-units-grid__table > *.has-error,
    .shipping-units-grid__table > *.has-error:focus {
        border: 1px solid #ce4844;
    }

    .shipping-units-grid__table > button {
        border: 1px solid #e0e0e0;
        border-top: none;
        color: #43B54A;
    }

    .shipping-units-grid__table > button.btn-default:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid #0a8f08;
        background-color: #e6e6e6;
    }

    .shipping-units-grid__table > button.btn-default:hover {
        background-color: #e6e6e6;
    }

    .shipping-units-grid__table > input:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid #0a8f08;
    }

    .shipping-units-grid__table > .shipping-units-grid__table__header {
        font-weight: bold;
        align-self: center;
        font-size: 0.925em;
        padding: 5px;
        border: none;
        border-bottom: 2px solid #e0e0e0;
        border-left: none;
    }

    .shipping-units-grid__table > .is-borderless {
        border: none;
    }

    .shipping-units-grid__table > .is-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shipping-units-grid__table > .is-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .shipping-units-grid__table > .is-paddingless {
        padding: 0;
    }

    .shipping-units-grid__table > .is-readonly {
        background-color: #f2f2f2;
    }

    .shipping-units-grid__table .input-number {
        text-align: right;
    }

    .shipping-units-grid__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .5em;
        margin-bottom: 1.5em;
    }

    .shipping-units-grid__actions__details {
        color: #777777;
        font-size: .925em;
    }

    .shipping-units-grid__actions__add-items {
        margin-left: auto;
        margin-right: 40px;
    }
</style>

<!-- Non scoped style -->
<style>
    .shipping-units-grid__table .v-select .vs__dropdown-toggle {
        height: 100%;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: none;
        padding: 0;
    }

    /*.shipping-units-grid__table .vs__actions .vs__open-indicator {*/
    /*display: none;*/
    /*}*/
</style>