<template>
    <div id="modalConsignmentItem" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header background-primary">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 class="modal-title"><span id="modalTitle">{{ !isNew ? 'Add new' : 'Edit' }} shipping unit</span></h4>
                </div>
                <div class="modal-body">
					<form id="consignmentInputForm">
						<ProductForm ref="productForm"
									 :product="product"
									 :submitted="formSubmitted"
									 :key="index"
									 @update="updateProduct"></ProductForm>
					</form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="save">{{ !isNew ? 'Save' : 'Update' }}</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import ProductForm from '../components/ProductForm'
  import saveProduct from '../myProductsApiService'

  export default {
    name: 'ProductsUpsertModal',
    components: {ProductForm},

    data: function () {
      return {
        product: null,
        index: 0,
        formSubmitted: false
      }
    },
    computed: {
      isNew () {
        return this.product != null && this.product.id != 0
      }
    },

    methods: {
      // this method opens the modal. It needs to be called by direct component reference as this is using bootstrap modals
      open (item) {
        this.formSubmitted = false
        this.index += 1

        if (item) { // edit product
          this.product = {
			id: item.Id,
            code: item.Code,
            name: item.Name,
            length: item.Length,
            width: item.Width,
            height: item.Height,
            weight: item.Weight,
            packaging: item.PackagingType ? item.PackagingType.Code : null,
            dangerousGoods: item.DangerousGoods,
            isActive: item.IsActive
          }
        } else { // add new
          this.product = {
            id: 0,
            dangerousGoods: [],
            isActive: true
          }
        }

        $('#modalConsignmentItem').modal('show')
      },

      updateProduct (productFormModel) {
        this.product = {...this.product, ...productFormModel}
      },

      save () {
        this.formSubmitted = true

        this.$nextTick(() => {
          if (!this.$refs.productForm.$v.$error) {
            saveProduct(this.product)
              .then(() => {
                $('#modalConsignmentItem').modal('hide')
                window['swal']('Saved', '', 'success')

                this.$emit('saved')
              })
              .catch(() => {
                $.okDialog("The shipping unit already exists. Please enter a different code to create a new shipping unit.");
              })
          }
        })
      },
    }
  }
</script>
