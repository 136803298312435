<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header background-primary">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 class="modal-title"><span>Add Dangerous Goods</span></h4>
                </div>
                <div class="no-content-blurb" v-if="dgNoContent">
                    <p>No Dangerous Goods found. Please contact your account manager or customer service.</p>
                </div>
                <div class="modal-body" id="mainContent" v-else>
                    <div class="info-banner" v-if="dgBlurbEnabled">
                        <p>If you have selected to add DG to one or more of your items, this information may not be received by your carrier at this pre-booking stage.</p>
                        <p>It is your responsibility to ensure that all DG details are validated and accurate at the time of consignment creation and declared correctly on the driver manifest.</p>
                    </div>

                    <div>
                        <p>Select the appropriate UN Number and add the number of receptacles for each packaging type in the Shipping Item row.</p>
                    </div>
                    <table class="table dataTable table-condensed" ref="dgTable">
                        <thead>
                            <tr>
                                <th>UnCode</th>
                                <th>Trade Name</th>
                                <th>PSN</th>
                                <th>Group</th>
                                <th>Class</th>
                                <th>Sub Risk</th>
                                <th width="30px">Agg Quantity per Receptacle</th>
                                <th>Receptacle</th>
                                <th data-orderable="false" width="30px">Quantity per Packaging</th>
                                <th data-orderable="false"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="_dgOption in dgOptions">
                                <td>{{ _dgOption.UNCode }}</td>
                                <td>{{ _dgOption.Name }}</td>
                                <td>{{ _dgOption.PSN }}</td>
                                <td>{{ _dgOption.Group }}</td>
                                <td>{{ _dgOption.Class }}</td>
                                <td>{{ _dgOption.SubRisk }}</td>
                                <td>{{ _dgOption.AggQty }}</td>
                                <td>{{ _dgOption.Receptacle }}</td>
                                <td><input rel="txtDGQuantity" type="text" v-model="_dgOption.Quantity" /></td>
                                <td><input rel="selectDGs" type="checkbox" :value="_dgOption.ID" v-model="dgs" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="save" id="save" v-if="dgNoContent === false">Save DGs</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'ShippingUnitsGridDGsModal',
    props: ['shippingUnits', 'value'],
    model: {
      prop: 'value',
      event: 'change'
    },

    data () {
      return {
        dgOptions: [],
        dgs: this.value ? this.value.map(dg => dg.Id) : [],
        dgBlurbEnabled: false,
        dgNoContent: false,
      }
    },
    watch: {
        value(value){
          this.dgs = value ? value.map(dg => dg.ID) : []
        }
    },

    methods: {
      save () {
        this.$emit('change', this.dgs.map((dgId) => {
          const dgOption = this.dgOptions.find(option => option.ID == dgId)

          return {
            AccountID: dgOption.AccountID,
            ID: dgOption.ID,
            DGID: dgOption.DGID,
            UNCode: dgOption.UNCode,
            PSN: dgOption.PSN,
            Name: dgOption.Name,
            Receptacle: dgOption.Receptacle,
            Group: dgOption.Group,
            Class: dgOption.Class,
            SubRisk: dgOption.SubRisk,
            Quantity: dgOption.Quantity,
            AggQty: dgOption.AggQty,
            Units: dgOption.Units
          }
        }))
        $(this.$refs.modal).modal('hide')
      },

      open () {
        $(this.$refs.modal).modal('show')

        if (this.dgOptions.length == 0) {
          $.ajax({
            type: 'GET',
            url: '/create-consignments/DGs',
              success: (data) => {
                  if (data.AccountDGItems === null) {
                    this.$set(this, 'dgNoContent', true)
                    return;
                  }
                  this.$set(this, 'dgNoContent', false)

                  this.$set(this, 'dgOptions', data.AccountDGItems.map(dgOption => {
                      const matchDG = this.value.find(dg => dg.Id === dgOption.ID)
                      return { ...dgOption, Quantity: matchDG ? matchDG.Quantity : dgOption.Quantity }
                  }))

                  this.$set(this, 'dgBlurbEnabled', data.ShowDGBlurb)

                  this.$nextTick(() => {
                    $(this.$refs.dgTable).DataTable()
                  })
            },
            error: function (request, status, error) {
              $.okDialog(error)
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
    .info-banner {
        border: 1px solid red;
        padding: 10px;
        margin: 10px 10px 20px 10px;
        text-align:left;
        font-weight:800;
    }

    .no-content-blurb {
        padding: 10px;
        margin: 10px 10px 20px 10px;
        text-align: left;
        font-weight: 800;
    }
</style>