/**
 * Created by Mauricio.Ruiz on 22/10/2015.
 */
( function ( app ) {

    /* Factory */
    (function ( factory ) {
            'use strict';

            app.Modules.ItemTableModule = factory;

        }( function () {
            'use strict';

            var $table,
                $body,
                $newRow,
                $toggleNewRowButton,
                $addRowButton,
                data = $( {} );

            // UTILS
            var loopRows = function ( fun ) {
                [].forEach.call( $table.fnGetNodes(), fun );
            };

            var loopRow = function ( $tr, fun ) {
                if ( typeof fun === 'function' ) {
                    [].forEach.call( $tr.find( '[data-type]' ), fun );
                }
                else {
                    app.log( 1, 'Module: "itemsTableModule". FAILED: LoopRow attributes are invalid' );
                }
            };

            var getRowData = function ( $tr ) {
                var rowData = {};

                loopRow( $tr, function ( el ) {
                    var $el = $( el ),
                        val;

                    if ( $el.tagName() === 'INPUT' ) {
                        if ( $el.attr( 'type' ) === "number" ) {
                            val = parseFloat( $el.val() )
                        }
                        else if ( $el.attr( 'type' ) === "checkbox" ) {
                            val = $el.is( ':checked' ) ? 1 : 0;
                        }
                    }
                    else if ( $el.tagName() === 'SPAN' ) {
                        val = $el.data( 'val' );
                    }

                    rowData[ $el.data( 'type' ) ] = val;
                } );

                return rowData
            };

            // METHODS
            var createTable = function () {
                $table = $table.dataTable( {
                    "iDisplayLength": parseInt( '@ViewBag.GridRecords' ),
                    "bSort": false,
                    "bFilter": false,
                    "bLengthChange": false,
                    "bPaginate": false,
                    "bInfo": false,
                    "oLanguage": {
                        "sEmptyTable": "Please add some items to start"
                    }
                } );
            };

            var toggleNewRow = function () {
                $newRow.toggle( 500 );
                $toggleNewRowButton.toggleClass( 'glyphicon-plus' ).toggleClass( 'glyphicon-minus' );
                selectFirstInput();
            };

            var openNewRow = function () {
                $newRow.show( 500 );
                $toggleNewRowButton.removeClass( 'glyphicon-plus' ).addClass( 'glyphicon-minus' );
            };

            var cleanNewRow = function () {
                loopRow( $newRow, function ( el ) {
                    var $el = $( el );
                    if ( $el.tagName() === 'INPUT' ) {
                        if ( $el.attr( 'type' ) === "checkbox" ) {
                            $el.prop( 'checked', false );
                        }
                        else {
                            $el.val( '' );
                        }
                    }
                } );
                selectFirstInput();
            };

            var selectFirstInput = function () {
                $newRow.find( 'input' ).get( 0 ).focus();
            };

            var saveRow = function () {
                var row = getRowData( $newRow );

                if ( validateRow( row ) ) {
                    $table.fnAddData( [
                        '<td><span data-type="NoItems" data-val="' + row.NoItems + '" >' + row.NoItems + '</span></td>',
                        '<td><span data-type="Length" data-val="' + row.Length + '" >' + row.Length + '</span></td>',
                        '<td><span data-type="Width" data-val="' + row.Width + '" >' + row.Width + '</span></td>',
                        '<td><span data-type="Height" data-val="' + row.Height + '" >' + row.Height + '</span></td>',
                        '<td><span data-type="Weight" data-val="' + row.Weight + '" >' + row.Weight + '</span></td>',
                        '<td><span data-type="Volume" data-val="' + row.NoItems * (row.Length * row.Height * row.Width) + '" >' + row.NoItems * (row.Length * row.Height * row.Width) + '</span></td>',
                        '<td><span data-type="dg" data-val="' + row.dg + '" >' + ( row.dg === 1 ? 'yes' : 'no' ) + '</span></td>',
                        '<td><a data-edit class="glyphicon glyphicon-pencil"></a></td>',
                        '<td><a data-delete class="glyphicon glyphicon-trash"></a></td>'
                    ] );

                    cleanNewRow();
                    data.trigger( 'dataChange' );
                }
                else {
                    $addRowButton.showTooltip();
                }
            };

            /**
             * @return {boolean}
             */
            var validateRow = function ( row ) {
                return ( !isNaN( row[ 'NoItems' ] ) && row[ 'NoItems' ] !== 0
                && !isNaN( row[ 'Length' ] ) && row[ 'Length' ] !== 0
                && !isNaN( row[ 'Width' ] ) && row[ 'Width' ] !== 0
                && !isNaN( row[ 'Height' ] ) && row[ 'Height' ] !== 0
                && !isNaN( row[ 'Weight' ] ) && row[ 'Weight' ] !== 0);
            };

            var setInputData = function ( rowData ) {
                loopRow( $newRow, function ( el ) {
                    var $el = $( el );

                    if ( $el.tagName() === 'INPUT' ) {
                        if ( $el.attr( 'type' ) === "checkbox" ) {
                            $el.prop( "checked", rowData[ $el.data( 'type' ) ] == 1 );
                        }
                        else {
                            $el.val( rowData[ $el.data( 'type' ) ] );
                        }
                    }
                } );

                openNewRow();
            };

            var editRow = function () {
                var rowData;

                rowData = getRowData( $( this ).closest( 'tr' ) );
                setInputData( rowData );
                deleteRow.call( this );
                selectFirstInput();
            };

            var deleteRow = function () {
                var row = $( this ).closest( 'tr' ).get( 0 ),
                    index;

                index = $table.fnGetPosition( row );
                $table.fnDeleteRow( index );
                data.trigger( 'dataChange' );
            };

            return {
                $data: data,
                init: function ( _el ) {
                    $table              = $( _el );
                    $body               = $table.find( 'tbody' );
                    $toggleNewRowButton = $table.find( '[data-toggle-new-row]' );
                    $newRow             = $table.find( '[data-new-row]' );
                    $addRowButton       = $newRow.find( '[data-add-row]' );

                    createTable();
                    $addRowButton.tooltip( {
                        title: 'Please fill all required spaces',
                        trigger: 'manual'
                    } );

                    $toggleNewRowButton.on( 'click', toggleNewRow );
                    $newRow.on( 'click', '[data-clean]', cleanNewRow );
                    $newRow.on( 'keyup', '[data-type]', function ( ev ) {
                        if ( ev.keyCode === 13 ) {
                            saveRow()
                        }
                        else if ( ev.keyCode === 27 ) {
                            toggleNewRow();
                        }
                    } );
                    $addRowButton.on( 'click', saveRow );
                    $body.on( 'click', '[data-edit]', editRow );
                    $body.on( 'click', '[data-delete]', deleteRow );
                },
                getTotals: function () {
                    var response = {};

                    loopRows( function ( _el ) {
                        var rowData = getRowData( $( _el ) );

                        for ( var el in rowData ) {
                            if ( rowData.hasOwnProperty( el ) ) {
                                if ( response.hasOwnProperty( el ) ) {
                                    response[ el ] += parseFloat( rowData[ el ] );
                                }
                                else {
                                    response[ el ] = parseFloat( rowData[ el ] );
                                }
                            }
                        }
                    } );

                    return response;
                },
                getItems: function () {
                    var response = [];

                    loopRows( function ( el ) {
                        response.push( getRowData( $( el ) ) );
                    } );

                    if ( response.length === 0 ) {
                        app.log( 2, 'No items added' );
                    }

                    return response;
                },
                cleanTable: function () {
                    $table.fnClearTable();
                    cleanNewRow();
                },
                
            };
        } )
    );

}( Enodo || window ));