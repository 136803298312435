(function ( $ ) {

    if ( typeof _wpcf7 == 'undefined' || _wpcf7 === null )
        _wpcf7 = {};

    _wpcf7 = $.extend( { cached: 0 }, _wpcf7 );

    $( function () {
        _wpcf7.supportHtml5 = $.wpcf7SupportHtml5();
        $( 'div.wpcf7 > form' ).wpcf7InitForm();
    } );

    $.fn.wpcf7InitForm = function () {
        this.ajaxForm( {
            beforeSubmit: function ( arr, $form, options ) {
                $form.wpcf7ClearResponseOutput();
                $form.find( '[aria-invalid]' ).attr( 'aria-invalid', 'false' );
                $form.find( 'img.ajax-loader' ).css( { visibility: 'visible' } );
                return true;
            },
            beforeSerialize: function ( $form, options ) {
                $form.find( '[placeholder].placeheld' ).each( function ( i, n ) {
                    $( n ).val( '' );
                } );
                return true;
            },
            data: { '_wpcf7_is_ajax_call': 1 },
            dataType: 'json',
            success: $.wpcf7AjaxSuccess,
            error: function ( xhr, status, error, $form ) {
                var e = $( '<div class="ajax-error"></div>' ).text( error.message );
                $form.after( e );
            }
        } );

        if ( _wpcf7.cached )
            this.wpcf7OnloadRefill();

        this.wpcf7ToggleSubmit();

        this.find( '.wpcf7-submit' ).wpcf7AjaxLoader();

        this.find( '.wpcf7-acceptance' ).click( function () {
            $( this ).closest( 'form' ).wpcf7ToggleSubmit();
        } );

        this.find( '.wpcf7-exclusive-checkbox' ).wpcf7ExclusiveCheckbox();

        this.find( '.wpcf7-list-item.has-free-text' ).wpcf7ToggleCheckboxFreetext();

        this.find( '[placeholder]' ).wpcf7Placeholder();

        if ( _wpcf7.jqueryUi && !_wpcf7.supportHtml5.date ) {
            this.find( 'input.wpcf7-date[type="date"]' ).each( function () {
                $( this ).datepicker( {
                    dateFormat: 'yy-mm-dd',
                    minDate: new Date( $( this ).attr( 'min' ) ),
                    maxDate: new Date( $( this ).attr( 'max' ) )
                } );
            } );
        }

        if ( _wpcf7.jqueryUi && !_wpcf7.supportHtml5.number ) {
            this.find( 'input.wpcf7-number[type="number"]' ).each( function () {
                $( this ).spinner( {
                    min: $( this ).attr( 'min' ),
                    max: $( this ).attr( 'max' ),
                    step: $( this ).attr( 'step' )
                } );
            } );
        }
    };

    $.wpcf7AjaxSuccess = function ( data, status, xhr, $form ) {
        if ( !$.isPlainObject( data ) || $.isEmptyObject( data ) )
            return;

        var $responseOutput = $form.find( 'div.wpcf7-response-output' );

        $form.wpcf7ClearResponseOutput();

        $form.find( '.wpcf7-form-control' ).removeClass( 'wpcf7-not-valid' );
        $form.removeClass( 'invalid spam sent failed' );

        if ( data.captcha )
            $form.wpcf7RefillCaptcha( data.captcha );

        if ( data.quiz )
            $form.wpcf7RefillQuiz( data.quiz );

        if ( data.invalids ) {
            $.each( data.invalids, function ( i, n ) {
                $form.find( n.into ).wpcf7NotValidTip( n.message );
                $form.find( n.into ).find( '.wpcf7-form-control' ).addClass( 'wpcf7-not-valid' );
                $form.find( n.into ).find( '[aria-invalid]' ).attr( 'aria-invalid', 'true' );
            } );

            $responseOutput.addClass( 'wpcf7-validation-errors' );
            $form.addClass( 'invalid' );

            $( data.into ).trigger( 'invalid.wpcf7' );

        }
        else if ( 1 == data.spam ) {
            $responseOutput.addClass( 'wpcf7-spam-blocked' );
            $form.addClass( 'spam' );

            $( data.into ).trigger( 'spam.wpcf7' );

        }
        else if ( 1 == data.mailSent ) {
            $responseOutput.addClass( 'wpcf7-mail-sent-ok' );
            $form.addClass( 'sent' );

            if ( data.onSentOk )
                $.each( data.onSentOk, function ( i, n ) { eval( n ) } );

            $( data.into ).trigger( 'mailsent.wpcf7' );

        }
        else {
            $responseOutput.addClass( 'wpcf7-mail-sent-ng' );
            $form.addClass( 'failed' );

            $( data.into ).trigger( 'mailfailed.wpcf7' );
        }

        if ( data.onSubmit )
            $.each( data.onSubmit, function ( i, n ) { eval( n ) } );

        $( data.into ).trigger( 'submit.wpcf7' );

        if ( 1 == data.mailSent )
            $form.resetForm().clearForm();

        $form.find( '[placeholder].placeheld' ).each( function ( i, n ) {
            $( n ).val( $( n ).attr( 'placeholder' ) );
        } );

        $responseOutput.append( data.message ).slideDown( 'fast' );
        $responseOutput.attr( 'role', 'alert' );
    }

    $.fn.wpcf7ExclusiveCheckbox = function () {
        return this.find( 'input:checkbox' ).click( function () {
            $( this ).closest( '.wpcf7-checkbox' ).find( 'input:checkbox' ).not( this ).removeAttr( 'checked' );
        } );
    };

    $.fn.wpcf7Placeholder = function () {
        if ( _wpcf7.supportHtml5.placeholder )
            return this;

        return this.each( function () {
            $( this ).val( $( this ).attr( 'placeholder' ) );
            $( this ).addClass( 'placeheld' );

            $( this ).focus( function () {
                if ( $( this ).hasClass( 'placeheld' ) )
                    $( this ).val( '' ).removeClass( 'placeheld' );
            } );

            $( this ).blur( function () {
                if ( '' == $( this ).val() ) {
                    $( this ).val( $( this ).attr( 'placeholder' ) );
                    $( this ).addClass( 'placeheld' );
                }
            } );
        } );
    };

    $.fn.wpcf7AjaxLoader = function () {
        return this.each( function () {
            var loader = $( '<img class="ajax-loader" />' )
                .attr( { src: _wpcf7.loaderUrl, alt: _wpcf7.sending } )
                .css( 'visibility', 'hidden' );

            $( this ).after( loader );
        } );
    };

    $.fn.wpcf7ToggleSubmit = function () {
        return this.each( function () {
            var form = $( this );
            if ( this.tagName.toLowerCase() != 'form' )
                form = $( this ).find( 'form' ).first();

            if ( form.hasClass( "wpcf7-acceptance-as-validation" ) )
                return;

            var submit = form.find( "input:submit" );
            if ( !submit.length ) return;

            var acceptances = form.find( "input:checkbox.wpcf7-acceptance" );
            if ( !acceptances.length ) return;

            submit.removeAttr( "disabled" );
            acceptances.each( function ( i, n ) {
                n = $( n );
                if ( n.hasClass( 'wpcf7-invert' ) && n.is( ':checked' )
                    || !n.hasClass( 'wpcf7-invert' ) && !n.is( ':checked' ) )
                    submit.attr( 'disabled', 'disabled' );
            } );
        } );
    };

    $.fn.wpcf7ToggleCheckboxFreetext = function () {
        return this.each( function () {
            var $wrap = $( this ).closest( '.wpcf7-form-control' );

            if ( $( this ).find( ':checkbox, :radio' ).is( ':checked' ) ) {
                $( this ).find( ':input.wpcf7-free-text' ).prop( 'disabled', false );
            }
            else {
                $( this ).find( ':input.wpcf7-free-text' ).prop( 'disabled', true );
            }

            $wrap.find( ':checkbox, :radio' ).change( function () {
                var $cb       = $( '.has-free-text', $wrap ).find( ':checkbox, :radio' );
                var $freetext = $( ':input.wpcf7-free-text', $wrap );

                if ( $cb.is( ':checked' ) ) {
                    $freetext.prop( 'disabled', false ).focus();
                }
                else {
                    $freetext.prop( 'disabled', true );
                }
            } );
        } );
    };

    $.fn.wpcf7NotValidTip = function ( message ) {
        return this.each( function () {
            var $into = $( this );
            $into.hide().append( '<span role="alert" class="wpcf7-not-valid-tip">' + message + '</span>' ).slideDown( 'fast' );

            if ( $into.is( '.use-floating-validation-tip *' ) ) {
                $( '.wpcf7-not-valid-tip', $into ).mouseover( function () {
                    $( this ).wpcf7FadeOut();
                } );

                $( ':input', $into ).focus( function () {
                    $( '.wpcf7-not-valid-tip', $into ).not( ':hidden' ).wpcf7FadeOut();
                } );
            }
        } );
    };

    $.fn.wpcf7FadeOut = function () {
        return this.each( function () {
            $( this ).animate( {
                opacity: 0
            }, 'fast', function () {
                $( this ).css( { 'z-index': -100 } );
            } );
        } );
    };

    $.fn.wpcf7OnloadRefill = function () {
        return this.each( function () {
            var url = $( this ).attr( 'action' );
            if ( 0 < url.indexOf( '#' ) )
                url = url.substr( 0, url.indexOf( '#' ) );

            var id      = $( this ).find( 'input[name="_wpcf7"]' ).val();
            var unitTag = $( this ).find( 'input[name="_wpcf7_unit_tag"]' ).val();

            $.getJSON( url,
                { _wpcf7_is_ajax_call: 1, _wpcf7: id, _wpcf7_request_ver: $.now() },
                function ( data ) {
                    if ( data && data.captcha )
                        $( '#' + unitTag ).wpcf7RefillCaptcha( data.captcha );

                    if ( data && data.quiz )
                        $( '#' + unitTag ).wpcf7RefillQuiz( data.quiz );
                }
            );
        } );
    };

    $.fn.wpcf7RefillCaptcha = function ( captcha ) {
        return this.each( function () {
            var form = $( this );

            $.each( captcha, function ( i, n ) {
                form.find( ':input[name="' + i + '"]' ).clearFields();
                form.find( 'img.wpcf7-captcha-' + i ).attr( 'src', n );
                var match = /([0-9]+)\.(png|gif|jpeg)$/.exec( n );
                form.find( 'input:hidden[name="_wpcf7_captcha_challenge_' + i + '"]' ).attr( 'value', match[ 1 ] );
            } );
        } );
    };

    $.fn.wpcf7RefillQuiz = function ( quiz ) {
        return this.each( function () {
            var form = $( this );

            $.each( quiz, function ( i, n ) {
                form.find( ':input[name="' + i + '"]' ).clearFields();
                form.find( ':input[name="' + i + '"]' ).siblings( 'span.wpcf7-quiz-label' ).text( n[ 0 ] );
                form.find( 'input:hidden[name="_wpcf7_quiz_answer_' + i + '"]' ).attr( 'value', n[ 1 ] );
            } );
        } );
    };

    $.fn.wpcf7ClearResponseOutput = function () {
        return this.each( function () {
            $( this ).find( 'div.wpcf7-response-output' ).hide().empty().removeClass( 'wpcf7-mail-sent-ok wpcf7-mail-sent-ng wpcf7-validation-errors wpcf7-spam-blocked' ).removeAttr( 'role' );
            $( this ).find( 'span.wpcf7-not-valid-tip' ).remove();
            $( this ).find( 'img.ajax-loader' ).css( { visibility: 'hidden' } );
        } );
    };

    $.wpcf7SupportHtml5 = function () {
        var features = {};
        var input    = document.createElement( 'input' );

        features.placeholder = 'placeholder' in input;

        var inputTypes = [ 'email', 'url', 'tel', 'number', 'range', 'date' ];

        $.each( inputTypes, function ( index, value ) {
            input.setAttribute( 'type', value );
            features[ value ] = input.type !== 'text';
        } );

        return features;
    };

    //------------------------------------   OSP  ----------------------------------------------------------------------

    $.isNumber = function ( n ) {
        n = n.replace( ',', '' );
        return !isNaN( parseFloat( n ) ) && isFinite( n );
    };

    var getDialogLevelType = function ( level ) {
        var type = "";

        if ( level === null ) {
            type = "info"
        }
        else if ( level === 1 ) {
            type = "success"
        }
        else if ( level === 2 ) {
            type = "warning"
        }
        else if ( level === 3 ) {
            type = "error"
        }

        return type;
    };

    $.confirmDialog = function ( text, title, level, okFunction ) {
        swal( {
            title: title || $( '#page-title' ).val(),
            text: text,
            type: getDialogLevelType( level ),
            showCancelButton: true,
            confirmButtonColor: "#4cae4c",
            confirmButtonText: "Ok",
            closeOnConfirm: true
        }, function () {
            if ( typeof okFunction == 'function' ) {
                okFunction();
            }
        } );
    };

    $.okDialog = function ( text, okFunction, title, level ) {
        swal( {
            title: title || $( '#page-title' ).val(),
            text: text,
            type: getDialogLevelType( level ),
            showCancelButton: false,
            confirmButtonColor: "#4cae4c",
            confirmButtonText: "Ok",
            closeOnConfirm: true
        }, function () {
            if (typeof okFunction === "function") {
                okFunction();
            }
            else if (okFunction) {
                $.okDialogFunction();
            }
        } );
    };

    $.okcancelDialog = function ( text, param, cancelFunction, title ) {

        swal( {
            title: title || $( '#page-title' ).val(),
            text: text,
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#4cae4c",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function ( isConfirm ) {
            if ( isConfirm ) {
                $.okDialogFunction( param );
            }
            else {
                if ( cancelFunction ) {
                    $.cancelDialogFunction( param );
                }
            }
        } );
    };

    $.okAjaxDialog = function ( text, title, level ) {
        swal( {
            title: title || $( '#page-title' ).val(),
            text: text,
            type: getDialogLevelType( level ),
            confirmButtonColor: "#4cae4c",
            showCancelButton: true,
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            html: true
        }, function () {
            $.okDialogFunction();
        } );
    };

    $.parseStringToDate = function ( value ) {
        if ( value == "" )
            return "";
        var dateresult = value.split( '/' );
        return new Date( dateresult[ 2 ], dateresult[ 1 ] - 1, dateresult[ 0 ] );
    };

    $.isDateInRange = function ( aData, daterangeinput ) {
        var iColumn = 1;
        var iMin    = (daterangeinput != "") ? daterangeinput.split( ' - ' )[ 0 ] : "";
        var iMax    = (daterangeinput != "") ? daterangeinput.split( ' - ' )[ 1 ] : "";
        var iDate   = aData[ iColumn ];

        iMin  = $.parseStringToDate( iMin );
        iMax  = $.parseStringToDate( iMax );
        iDate = $.parseStringToDate( iDate );

        if ( iMin == "" && iMax == "" ) {
            return true;
        }
        else if ( iMin == "" && iDate <= iMax ) {
            return true;
        }
        else if ( iMin <= iDate && "" == iMax ) {
            return true;
        }
        else if ( iMin <= iDate && iDate <= iMax ) {
            return true;
        }
        return false;
    }

    $.getRow = function ( tableName, currElement ) {
        var target_row = $( currElement ).closest( "tr" )[ 0 ];
        var aPos       = $( tableName ).dataTable().fnGetPosition( target_row );
        var aData      = $( tableName ).dataTable().fnGetData( aPos );
        return aData;
    };


    $.validatePhoneNumber = function (parentID) {
        var valid = true;
        var regex = /^[0-9\+]{1,}[0-9\ ]{3,15}$/;
        $('#' + parentID + ' *[phone]').each(function () {
            var trimValue = $(this).val().trim();
            if (trimValue !== "")
                if (!regex.test(trimValue)) {
                    valid = false;
                    $(this).addClass('error');
                }
        });
        return valid;
    };

    $.validateEmail = function (parentID) {
        var valid = true;
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        $('#' + parentID + ' *[email]').each(function () {
            var trimValue = $(this).val().trim();
            if (trimValue !== "")
                if (!regex.test(trimValue)) {
                    valid = false;
                    $(this).addClass('error');
                }
        });
        return valid;
    };

    $.validateRequired = function ( parentID ) {
        var valid = true;
        $('#' + parentID + ' *[required]').each(function () {
            var value = $(this).val();

            if (value == null) {
                valid = false;
                $(this).addClass('error');
                return;
            }

            if (typeof value === "object") {
                value = value.join();
            }

            if (value === null || value.trim() === "") {
                valid = false;
                $( this ).addClass( 'error' );
            }
        });

        return valid;
    };

    $.validatePositiveNumber = function ( parentID ) {
        var valid = true;
        $( '#' + parentID + ' input[data-positive-number]' ).each( function () {
            if ( $( this ).val() !== "" && (!$.isNumber( $( this ).val() ) || Number( $( this ).val() ) <= 0) )
                valid = false;
        } );
        $( '#' + parentID + ' input[data-positiveorzero-number]' ).each( function () {
            if ( $( this ).val() !== "" && (!$.isNumber( $( this ).val() ) || Number( $( this ).val() ) < 0) )
                valid = false;
        } );
        return valid;
    };

    $.cleanDGs = function () {
        $( '#unCode, #dgInputForm input' ).val( '' );
        $( '#unit' ).val( 'Kg' );
        $( '#packGroup' ).val( 'I' );
        $( '#panelDGs' ).addClass( 'hidden' );
        $( '#isDGs' ).prop( 'checked', false );
    };

    $( '#collapseExpandAll' ).live( 'click', function ( e ) {
        if ( $( '#collapseExpandAll' ).find( "i" ).hasClass( "fa-minus-square-o" ) ) {
            $( '#collapseExpandAll' ).find( "i" ).removeClass( "fa-minus-square-o" ).addClass( "fa-plus-square-o" );
            $( '.dataTable' ).dataTable().find( '.expanded-group' ).trigger( 'click' );
        }
        else {
            $( '#collapseExpandAll' ).find( "i" ).removeClass( "fa-plus-square-o" ).addClass( "fa-minus-square-o" );
            $( '.dataTable' ).dataTable().find( '.collapsed-group' ).trigger( 'click' );
        }
    } );

    $( '.collapsed-group' ).live( 'click', function ( e ) {
        if ( $( '.dataTable' ).dataTable().find( '.expanded-group' ).length === 0 )
            $( '#collapseExpandAll' ).find( "i" ).removeClass( "fa fa-minus-square-o" ).addClass( "fa fa-plus-square-o" );
        $( this ).find( "i" ).removeClass( "fa fa-minus-square-o" ).addClass( "fa fa-plus-square-o" );
    } );

    $( '.expanded-group' ).live( 'click', function ( e ) {
        $( '#collapseExpandAll' ).find( "i" ).removeClass( "fa fa-plus-square-o" ).addClass( "fa fa-minus-square-o" );
        $( this ).find( "i" ).removeClass( "fa fa-plus-square-o" ).addClass( "fa fa-minus-square-o" );
    } );

    $.refreshReportGridView = function ( reportUrl ) {
        var startDate = $( '#dateRangePicker' ).val().split( " - " )[ 0 ];
        var endDate   = $( '#dateRangePicker' ).val().split( " - " )[ 1 ];
        if ( moment( startDate, 'DD/MM/YYYY' ).add( 'months', 1 ) >= moment( endDate, 'DD/MM/YYYY' ) ) {
            $.ajax( {
                type: 'POST',
                url: rootUrl + reportUrl,
                timeout: conf.ajaxTimeout,
                data: {
                    fromDate: startDate,
                    toDate: endDate
                },
                success: function ( data ) {
                    $( '.table-responsive' ).replaceWith( "<div class='table-responsive' >" + data + "</div>" );
                    oTable = $.buildDataTable();
                },
                error: function ( request, status, error ) {
                    if (status == "timeout"){
                        $.okDialog("There is too much data to generate this report, please contact your Account Manager", false, "Timeout", 2);
                    }else {
                        $.okDialog(error);
                    }
                }
            } );
        }
        else {
            $.okDialog( 'Date range must not be more than one month.' );
        }
    };

    $( 'input,textarea' ).live( 'focus', function ( e ) {
        $( this ).removeClass( "error" );
    } );

    $( 'input,textarea' ).live( 'change', function ( e ) {
        $( this ).removeClass( "error" );
    } );

})( jQuery );