/**
 * Created by Mauricio.Ruiz on 25/07/2016.
 */
(function (app) {

    /* Inner Modules */
    var ReportViewer,
        ReportPrinter;

    /* Factory */
    (function (factory) {
        'use strict';

        app.SendItReporter = factory;

    }(function (_config) {
        'use strict';

        var config = {
            showViewer: false
        };

        var reporter,
            webViewReporter,
            services;

        var getServicesconfiguration = function () {
            return $.ajax({
                url: rootUrl + "/PrinterConfig/GetServiceConfiguration",
                type: "GET",
                dataType: "json"
            });
        };

        /* Init */
        var init = function () {
            config = $.extend(config, _config);

            services = getServicesconfiguration()
                .done(function (data) {
                    var _services = [];

                    data.forEach(function (service) {

                        _services[service.ValueMember] = {
                            consignment: {
                                printer: service.ReportPrinter,
                                copies: service.ReportCopies,
                                active: true
                            },
                            label: {
                                printer: service.LabelPrinter,
                                copies: service.LabelCopies,
                                active: true
                            },
                            data: service
                        }
                    });

                    services = _services;
                });

            if (config.showViewer) {
                reporter = new ReportViewer();
            } else {
                reporter = new ReportPrinter();
            }

            webViewReporter = new ReportViewer();
        };

        init();

        /* Public 
        reportConfig object:
            reportConfig.consignmentId
            reportConfig.manifestId
            reportConfig.service
            reportConfig.showViewer
            reportConfig.copies
            reportConfig.consolidated
            reportConfig.jobId
            reportConfig.downloadId
        
        */
        return {
            generateConsignmentReport: function (reportConfig) {
                try {
                    if (typeof services[reportConfig.service] === 'undefined' || services[reportConfig.service].consignment.active) {
                        if (config.showViewer || reportConfig.showViewer) {
                            webViewReporter.generateConsignmentReport(reportConfig, services[reportConfig.service]);
                        } else {
                            reporter.generateConsignmentReport(reportConfig, services[reportConfig.service]);
                        }
                    }
                } catch (e) {
                    Enodo.log(2, e);
                    webViewReporter.generateConsignmentReport(reportConfig, services[reportConfig.service]);
                }
            },
            generateLabelReport: function (reportConfig) {
                try {
                    if (typeof services[reportConfig.service] === 'undefined' || services[reportConfig.service].label.active) {
                        if (config.showViewer || reportConfig.showViewer) {
                            webViewReporter.generateLabelReport(reportConfig, services[reportConfig.service]);
                        } else {
                            reporter.generateLabelReport(reportConfig, services[reportConfig.service]);
                        }
                    }
                } catch (e) {
                    Enodo.log(2, e);
                    webViewReporter.generateLabelReport(reportConfig, services[reportConfig.service]);
                }

            },

            generateManifestReport: function (reportConfig) {
                webViewReporter.generateManifestReport(reportConfig);
            },

            generateQuoteReport: function (reportConfig) {
                webViewReporter.generateQuoteReport(reportConfig);
            },

            generateDownloadConsignmentReport: function (reportConfig) {
                webViewReporter.generateDownloadConsignmentReport(reportConfig);
            },

            generateDownloadLabelReport: function (reportConfig) {
                webViewReporter.generateDownloadLabelReport(reportConfig);
            }

        }

    })
    );

    ReportViewer = function () {

      this.generateConsignmentReport = function (reportConfig, service) {
        window.open(`/documents/consignment/${reportConfig.consignmentId}`, "Connote", "width=800,height=800");
      }

      this.generateLabelReport = function (reportConfig, service) {
        if (!reportConfig.consolidated) {
          window.open(`/documents/label/${reportConfig.consignmentId}`, "Label", "width=800,height=800");
        } else {
          window.open(`/documents/label/${reportConfig.consignmentId}/consolidated`, "Label", "width=800,height=800");
        }
      }

      this.generateManifestReport = function (reportConfig) {
        window.open(`/documents/manifest/${reportConfig.manifestId}`, 'Manifest', 'width=800,height=800')
      }

        this.generateQuoteReport = function (reportConfig) {
            window.open(`/documents/quotes/${reportConfig.jobId}`, "Quote", "width=800,height=800");
        }

        this.generateDownloadConsignmentReport = function (reportConfig) {
            window.open(`/documents/download/consignment/${reportConfig.downloadId}`, "Connote", "width=800,height=800");
        }

        this.generateDownloadLabelReport = function (reportConfig) {
          window.open(`/documents/download/label/${reportConfig.downloadId}`, "Connote", "width=800,height=800");
        }
    };

    ReportPrinter = function () {

        this.generateConsignmentReport = function (reportConfig, service) {
            $.ajax({
                "type": "post",
                "url": rootUrl + "/AutoPrinting/Connote",
                data: {
                    consignmentId: reportConfig.consignmentId,
                    serviceId: service.data.ServiceID,
                    copies: reportConfig.copies
                }
            });
        }
        this.generateLabelReport = function (reportConfig, service) {
            $.ajax({
                "type": "post",
                "url": rootUrl + "/AutoPrinting/ConsignmentLabel",
                data: {
                    consignmentId: reportConfig.consignmentId,
                    serviceId: service.data.ServiceID,
                    copies: reportConfig.copies,
                    consolidated: reportConfig.consolidated
                }
            });
        }
    };

}(Enodo || window));