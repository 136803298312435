<template>
    <div class="checkbox">
        <label :for="identifier">
            <input :id="identifier" type="checkbox"
                   :checked="checked"
                   :required="required"
                   @change="$emit('change', $event.target.checked)">{{ label }}
        </label>
    </div>
</template>

<script>
  export default {
    name: 'OFFormControl',
    model: {
      prop: 'checked',
      event: 'change'
    },
    props: {
      label: String,
      checked: Boolean,
      id: String,
      required: {type: Boolean, default: false}
    },
    computed: {
      identifier () {
        return this.id || 'of_control_' + this.label.replace(/\W/g, '')
      }
    }
  }
</script>

<style scoped>

</style>