<template>
    <div class="product-form">
        <p><b>Unit details:</b></p>
        <div class="row">
            <div class="col-sm-6">
                <OFFormInput ref="codeFormInput" label="Code/Type" :required="true" v-model.trim="$v.code.$model"
                             :error="$v.code.$error">
                    <div slot="errors">
                        <span v-if="!$v.code.required" class="help-block">This field is required</span>
                    </div>
                </OFFormInput>
            </div>
            <div class="col-sm-6">
                <OFPackagingInput v-model="packaging" :error="$v.packaging.$error" label="Packaging">
                    <div slot="errors">
                        <span v-if="!$v.packaging.required" class="help-block">This field is required</span>
                    </div>
                </OFPackagingInput>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-9">
                <OFFormInput label="Name" :required="true" v-model.trim="$v.name.$model"
                             :error="$v.name.$error">
                    <div slot="errors">
                        <span v-if="!$v.name.required" class="help-block">This field is required</span>
                    </div>
                </OFFormInput>
            </div>
            <div class="col-sm-3">
                <OFFormInput label="Weight (kg)" type="number" :required="true" v-model.trim="$v.weight.$model"
                             :error="$v.weight.$error">
                    <div slot="errors">
                        <span v-if="!$v.weight.required" class="help-block">This field is required</span>
                        <span v-if="!$v.weight.minValue" class="help-block">Value cannot be negative</span>
                    </div>
                </OFFormInput>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <OFFormInput label="Length (cm)" type="number" :required="true" v-model.trim="$v.length.$model"
                             :error="$v.length.$error">
                    <div slot="errors">
                        <span v-if="!$v.length.required" class="help-block">This field is required</span>
                        <span v-if="!$v.length.minValue" class="help-block">Value cannot be negative</span>
                    </div>
                </OFFormInput>
            </div>
            <div class="col-sm-3">
                <OFFormInput label="Width (cm)" type="number" :required="true" v-model.trim="$v.width.$model"
                             :error="$v.width.$error">
                    <div slot="errors">
                        <span v-if="!$v.width.required" class="help-block">This field is required</span>
                        <span v-if="!$v.width.minValue" class="help-block">Value cannot be negative</span>
                    </div>
                </OFFormInput>
            </div>
            <div class="col-sm-3">
                <OFFormInput label="Height (cm)" type="number" :required="true" v-model.trim="$v.height.$model"
                             :error="$v.height.$error">
                    <div slot="errors">
                        <span v-if="!$v.height.required" class="help-block">This field is required</span>
                        <span v-if="!$v.height.minValue" class="help-block">Value cannot be negative</span>
                    </div>
                </OFFormInput>
            </div>
            <div class="col-sm-3">
                <OFFormInput label="Volume (m3)" :read-only="true" :value="volume"></OFFormInput>
            </div>
        </div>
    </div>
</template>

<script>
  import OFPackagingInput from '../../../components/OFPackagingInput'
  import { decimal, minValue, required } from 'vuelidate/lib/validators'
  import greater from '../../../validators/greater'

  export default {
    name: 'ProductForm',
    components: {OFPackagingInput},
    props: ['product', 'submitted'],
    data: function () {
      return {
        id: this.product ? this.product.id : 0,
        code: this.product ? this.product.code : '',
        name: this.product ? this.product.name : '',
        length: this.product ? this.product.length : 0,
        width: this.product ? this.product.width : 0,
        height: this.product ? this.product.height : 0,
        weight: this.product ? this.product.weight : 0,
        packaging: this.product ? this.product.packaging : ''
      }
    },
    validations: {
      code: {required},
      name: {required},
      length: {required, decimal, minValue: minValue(1)},
      width: {required, decimal, positive: greater(0)},
      height: {required, decimal, positive: greater(0)},
      weight: {required, decimal, positive: greater(0)},
      packaging: {required}
    },
    watch: {
      submitted (value) {
        if (value) this.$v.$touch()
      }
    },
    computed: {
      volume () {
        return !isNaN(this.width) && !isNaN(this.height) && !isNaN(this.length) ? ((this.width * this.height * this.length) / 1000000).toString() : '-'
      }
    },

    mounted () {
      setTimeout(() => {
        document.getElementById(this.$refs.codeFormInput.identifier).focus()
      }, 500)
    },

    updated () {
      this.$emit('update', {
        id: this.id,
        code: this.code,
        name: this.name,
        length: this.length,
        width: this.width,
        height: this.height,
        weight: this.weight,
        packagingtype: this.packaging
      })
    }
  }
</script>

<style scoped>
    .row {
        margin-bottom: .5em;
    }
</style>