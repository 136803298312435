/*****************************************************************
 * 
 *
 * SUMMARY PANEL MODULE
 *
 * - string    templateName        Handelbars Template Id
 * - function  formValidation      Form validation function
 * - bool      summary             Summary view selector
 *
 * */
jQuery.fn.SummaryPanel = function (templateName, _formValidation, summary) {
    "use strict";

    var $el = $(this),
        $panelBody,
        $summaryWrapper = $('<div data-summary hidden></div>'),
        formValidation = _formValidation || function () { return true; },
        template = $('#' + templateName).html(),
        icon = '<div class="pull-right toggle-icon"><a data-toggle title="Save/Edit"><i class="fa icon"></i></a></div>',
        toggleTime = 250;

    var renderSummaryView = function () {
        var html = template($el.find(':input, textarea, select').serializeObject());
        $summaryWrapper.html(html);
    };

    var toggleView = function () {
        renderSummaryView();

        if ($el.hasClass('summary')) {
            $summaryWrapper.hide(toggleTime);
            $panelBody.show(toggleTime);
            $el.removeClass('summary');
            $el.find('.panel-body :input').first().focus();
        } else {
            if (formValidation()) {
                $summaryWrapper.show(toggleTime);
                $panelBody.hide(toggleTime);
                $el.addClass('summary');
            }
        }
    };

    var setHTML = function () {
        var $header = $el.find('.panel-heading');
        if ($header.find('.pull-right').length > 0) {
            $header.find('.pull-right').append(icon);
        } else {
            $header.append(icon);
        }

        $panelBody = $el.find('.panel-body');
        $panelBody.parent().append($summaryWrapper);
    };

    var init = function () {
        template = Handlebars.compile(template);

        setHTML();

        if (summary) {
            toggleView();
        }

        /* Events */
        $el.find('[data-toggle]')
            .on('click',
                function () {
                    toggleView();
                });
        $el.find(':input')
            .last()
            .on('blur',
                function (ev) {
                    if (!$el.has(ev.relatedTarget).length > 0) {
                        toggleView();
                    }
                });
    };

    init();
};