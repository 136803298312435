<template>
    <div class="form-group" :class="{'has-error': error}">
        <label v-if="label" class="control-label" :for="identifier">Packaging</label>

        <v-select placeholder="Search"
                  :key="selectKey"
                  label="description" 
                  :options="packagingTypes"
                  :selectOnTab="true"
                  :reduce="packaging => packaging.code"
                  :components="{OpenIndicator: null}"
                  :value="value"
                  :clearable="false"
                  @input="$emit('input', $event)">
        </v-select>
        <slot name="errors" v-if="error"></slot>
    </div>
</template>

<script>
  import { packagingTypeClient } from '../services/api-client-service'

  export default {
    name: 'OFPackagingInput',
    data () {
      return {
        packagingTypes: [],
        selectKey: 0
      }
    },
    props: {
      value: {},
      readOnly: {type: Boolean, default: false},
      required: {type: Boolean, default: false},
      label: {type: String},
      error: {default: false, type: Boolean}
    },
    computed: {
      identifier () {
        return 'of_packaging-control'
      },
      tabIndex () {
        return this.readOnly ? -1 : 0
      }
    },
    watch: {
      value () {
        this.selectKey += 1
      }
    },
    created () {
      packagingTypeClient.fetch()
        .then(packagingTypes => {
          this.packagingTypes = packagingTypes

          // we need to rerender the select to correctly setup the initial value if any
          this.selectKey += 1 
        })
    }
  }
</script>

<style scoped>

</style>