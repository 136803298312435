import './libs'
// COMMON
import './common/dataset'
import './common/EnodoTableModule'
import './common/helpers'
import './common/ItemTableModule'
import './common/autocomplete/CodeAutocomplete'
import './common/autocomplete/LocalityAutocomplete'
import './common/summaryPanel'
import './common/navigation'
import './common/scripts'
import './common/sendITReporter'
import './common/validator'

import './app/accountAlias'
// VIEWS
import './app/index'

/* Main App Core */
(function () {
    "use strict";
    
    /* Private Functions */
    var initPanels = function() {
        var $panels = $('.panel-enodo');

        $panels.on('focus',
            'input, select, button, a',
            function() {
                $(this).closest('.panel-enodo').addClass('selected');
            });
        $panels.on('focusout',
            'input, select, button, a',
            function() {
                $(this).closest('.panel-enodo').removeClass('selected');
            });
    };

    var initMenu = function($menu) {
        if ($menu.length == 0) {
            return false;
        }

        var $sidebar = $('#sidebar');

        $menu.metisMenu();
        $sidebar.show();

        if (localStorage.hasOwnProperty('menuId')) {
            var $lastSelection = $sidebar.find('a[data-id=' + localStorage.getItem('menuId') + ']');
            $lastSelection.addClass('active');
            $lastSelection.closest('ul').addClass('in');

            if (!$lastSelection.closest('ul').parent().closest('ul').hasClass('metismenu')) {
                $lastSelection.closest('ul').parent().closest('ul').addClass('in');
            }
        }

        $sidebar.on('click',
            'a',
            function(ev) {
                if (!$(this).siblings('ul').length > 0) {
                    window.localStorage.setItem('menuId', $(this).data('id'));
                }
            });
    };


    $(document)
        .ready(function() {
            try {
                if ($('#datepicker, .datepicker').length !== 0) {
                    $('#datepicker, .datepicker')
                        .datepicker({
                            dateFormat: "dd/mm/yy"
                        });
                }

                initMenu($('#main-menu'));
                initPanels();
                
                $('.custom-combobox')
                    .click(function() {
                        $(this).parent().find('.custom-combobox-input').select();
                    });
            } finally {
                $('#content').show();
            }

            /* Events */
            $(document).ajaxStart(function() { Enodo.processStart(); });
            $(document).ajaxComplete(function() { Enodo.processStop(); });
        });

}());