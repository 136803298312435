Number.prototype.formatPercentage = function(c) {
    var n = this;
    c = isNaN(c = Math.abs(c)) ? 2 : c;
    return parseFloat(n.toFixed(c)) + ' %';
};

String.prototype.ellipsis = function(length, firstLength) {
    length = length || 30;
    firstLength = firstLength || 10;
    if (this.length > length) {
        return this.substring(0, firstLength) +
            '...' +
            this.substring(this.length - (length - firstLength - 3), this.length);
    } else {
        return this;
    }
};

window.isValidEmailAddress = function(emailAddress) {
    var pattern =
        /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress) || emailAddress == "";
}

// endregion

jQuery.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a,
        function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
    return o;
};

jQuery.fn.tagName = function() {
    return this.prop("tagName");
};

jQuery.fn.showTooltip = function(time) {
    var that = this;
    time = time || 1800;

    that.tooltip('show');
    window.setInterval(function() {
            that.tooltip('hide');
        },
        time);
};

jQuery.fn.parentCheckbox = function(parentEl, checkboxClass, withBlocking) {
    'use strict';

    var $el = $(parentEl),
        $parentCheckbox = $(this);

    withBlocking = withBlocking || false;

    // METHODS
    var checkboxClickHandler = function() {
        var $checkboxes = $el.find(checkboxClass),
            isAllChecked = true;
        $.each($checkboxes,
            function(index, el) {
                isAllChecked = isAllChecked && $(el).is(':checked');
            });
        $parentCheckbox.attr('checked', isAllChecked);
    };

    var parentClickHandler = function() {
        var $checkboxes = $el.find(checkboxClass),
            parentStatus = $parentCheckbox.is(':checked');

        $.each($checkboxes,
            function(index, el) {
                $(el).attr('checked', parentStatus)
            });

        if (withBlocking) {
            return false;
        }
    };

    var init = function() {
        // Events
        $el.on('change', checkboxClass, checkboxClickHandler);
        $parentCheckbox.on('change', parentClickHandler)
    };

    init();
};

jQuery.fn.validatePhone = function() {
    this.val(this.val().replace(/[{() a-z}]/g, ''));
    return this.val().match(/^(\D*\d){9,11}\D*$/) !== null;
};

window.getMinutes = function(val) {
    var valArray = val.split(/[: ]+/);
    return ((parseInt(valArray[0]) + (valArray[2] === "PM" && valArray[0] != 12 ? 12 : 0)) * 60) +
        parseInt(valArray[1
        ]);
};

/* ReadyAt CloseAt*/
$.readyAtCloseAtInit = function(_minTime, $dateInput, _offset) {
    'use strict';

    /* Variables*/
    var minTime = _minTime == undefined || _minTime == null ? _minTime : '6:00 AM',
        maxTime = '10:00 PM',
        timeFormat = 'h:i A',
        defaultTime = '05:00 PM',
        offsetInHours = (_offset == undefined || _offset == null) ? (new Date().getTimezoneOffset() / -60)  : Number.parseFloat(_offset);

    /* DOM */
    var $readyAt = $('#readyAt').removeData(),
        $closeAt = $('#closeAt').removeData();

    /* Methods */
    var init = function() {
        readyAtInit(minTime, offsetInHours);
        closeAtInit($readyAt.val());
    };

    var events = function() {
        $readyAt.keydown(preventTyping);
        $closeAt.keydown(preventTyping);
        $readyAt.on('change', readyAtChangeHandler);

        if ($dateInput) {
            $dateInput.on('change', dateInputChangeHandler);
        }
    };

    var dateInputChangeHandler = function() {
        $readyAt.removeData();
        readyAtInit();

        $readyAt.trigger('change');
    };

    var preventTyping = function(e) {
        if (e.keyCode !== 13 && e.keyCode !== 9) {
            e.preventDefault();
        }
    };

    var readyAtChangeHandler = function() {
        $closeAt.removeData();
        closeAtInit($readyAt.val());
    };

    var readyAtInit = function(minTime, offsetInHours) {
        var localOffsetInHours = new Date().getTimezoneOffset() / -60;
        var offsetDifference = offsetInHours - localOffsetInHours;

        
        var currDate = moment().add(offsetDifference, 'hours').toDate();
        var minReadyAtTime = new Date(0,
            0,
            0,
            currDate.getMinutes() < 30 ? currDate.getHours() : currDate.getHours() + 1,
            currDate.getMinutes() < 30 ? 30 : "00",
            0);

        if ($dateInput !== undefined) { // DateInput linked
            var currDate = $.datepicker.formatDate('yy-mm-dd', new Date()),
                datePickerDate = $.datepicker.formatDate('yy-mm-dd', $dateInput.datepicker("getDate"));
            minReadyAtTime = datePickerDate > currDate ? minTime : minReadyAtTime;
        }

        $readyAt.timepicker({
            timeFormat: timeFormat,
            minTime: minReadyAtTime,
            maxTime: maxTime,
            scrollDefaultNow: true
        });

        if ($readyAt.val() === '')
            $readyAt.timepicker('setTime', defaultTime);
    };

    var closeAtInit = function(minTime) {
        $closeAt.timepicker({
            timeFormat: timeFormat,
            minTime: minTime,
            maxTime: maxTime,
            scrollDefaultNow: true
        });

        if ($closeAt.val() === '') {
            console.log($closeAt.val());
            $closeAt.timepicker('setTime', defaultTime);
        }

        if (getMinutes($closeAt.val()) < getMinutes(minTime)) {
            $closeAt.timepicker('setTime', minTime);
        }
    };

    init();
    events();
};

// Combobox
$.widget("custom.combobox",
{
    _create: function() {
        this.wrapper = $("<span>")
            .addClass("custom-combobox")
            .insertAfter(this.element);

        this.element.hide();
        this._createAutocomplete();
        this._createShowAllButton();
    },

    _createAutocomplete: function() {
        var selected = this.element.children(":selected"),
            value = selected.val() ? selected.text() : "";

        this.input = $("<input>")
            .appendTo(this.wrapper)
            .val(value)
            .attr("title", "")
            .addClass("custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left")
            .autocomplete({
                delay: 0,
                minLength: 0,
                source: $.proxy(this, "_source")
            })
            .tooltip({
                tooltipClass: "ui-state-highlight"
            });

      this.element.on('change', () => {
            const selected = this.element.children(":selected")
            const value = selected.val() ? selected.text() : "";
            
            this.input.val(value)
        });

        this._on(this.input,
        {
            autocompleteselect: function(event, ui) {
                ui.item.option.selected = true;
                this._trigger("select",
                    event,
                    {
                        item: ui.item.option
                    });
            },

            autocompletechange: "_removeIfInvalid"
        });
    },

    _createShowAllButton: function() { 
        var input = this.input,
            wasOpen = false;
        
        $("<a>")
            .attr("tabIndex", -1)
            .tooltip()
            .appendTo(this.wrapper)
            .button({
                icons: {
                    primary: "ui-icon-triangle-1-s"
                },
                text: false
            })
            .removeClass("ui-corner-all")
            .addClass("custom-combobox-toggle ui-corner-right glyphicon glyphicon-menu-down")
            .mousedown(function() {
                wasOpen = input.autocomplete("widget").is(":visible");
            })
            .click(function() {
                input.focus();

                // Close if already visible
                if (wasOpen) {
                    return;
                }

                // Pass empty string as value to search for, displaying all results
                input.autocomplete("search", "");
            });
    },

    _source: function(request, response) {
        var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
        response(this.element.children("option")
            .map(function() {
                var text = $(this).text();
                if (this.value && (!request.term || matcher.test(text)))
                    return {
                        label: text,
                        value: text,
                        option: this
                    };
            }));
    },

    _removeIfInvalid: function(event, ui) {

        // Selected an item, nothing to do
        if (ui.item) {
            return;
        }

        // Search for a match (case-insensitive)
        var value = this.input.val(),
            valueLowerCase = value.toLowerCase(),
            valid = false;
        this.element.children("option")
            .each(function() {
                if ($(this).text().toLowerCase() === valueLowerCase) {
                    this.selected = valid = true;
                    return false;
                }
            });

        // Found a match, nothing to do
        if (valid) {
            return;
        }

        // Remove invalid value
        this.input
            .val("")
            .attr("title", value + " didn't match any item")
            .tooltip("open");
        this.element.val("");
        this._delay(function() {
                this.input.tooltip("close").attr("title", "");
            },
            2500);
        this.input.autocomplete("instance").term = "";
    },

    _destroy: function() {
        this.wrapper.remove();
        this.element.show();
    }
});

/* 
* DevExtreme Extension
*/
$.prototype.dxExtension = function() {

    var $el = this;

    function escape(str) {
        return '"' + str + '"';
    }

    function convertToCsv(objArray, headers) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        var line = '';

        headers.forEach(function(header) {
            if (line != '') line += ',';

            line += escape(header.caption);
        });

        str += line + '\r\n';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            headers.forEach(function (header) {
                if (line != '') line += ',';

                line += escape(array[i][header.dataField]);
            });

            str += line + '\r\n';
        }

        return str;
    }

    function getHeaders(tableInstance) {
        var dataGridInstance = tableInstance,
            columnCount = dataGridInstance.columnCount(),
            headers = [],
            i;
        for (i = 0; i < columnCount; i++) {
            if (!dataGridInstance.columnOption(i, "skipOnExport")) {
                headers.push(dataGridInstance.columnOption(i));
            }
        }
        return headers;
    }

    function exportToCsv(e) {            
        var tableInstance = $el.dxDataGrid("instance");
        var headers = getHeaders(tableInstance);
        var csv = convertToCsv(tableInstance.option("dataSource").store.data, headers);       
        var blob = new Blob(["\ufeff", csv]);

        if (window.navigator.msSaveBlob != undefined) {
            window.navigator.msSaveBlob(blob, 'data.csv');
        } else {
            var downloadLink = jQuery('<a/>', {
                id: 'download-link',
                download: 'data.csv',
                href: URL.createObjectURL(blob)
            }).appendTo(document.body);

            downloadLink[0].click();
            downloadLink.remove();
        }
    }

    $el.dxDataGrid("instance")
        .on('contentReady',
            function() {
                $el.find("#dx-export-csv").remove();
                var exportBtn = $('<a id="dx-export-csv" class="btn btn-default">Export to Csv</a>');
                $el.find("div.dx-toolbar-after").prepend(exportBtn);
                exportBtn.click(exportToCsv);
            });
}
