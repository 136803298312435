/*
 * Validator
 * */
(function ( $, factory ) {

    typeof jQuery == 'function' ? jQuery.fn.validateForm = factory : console.error("jQuery required");

})( jQuery, function () {
    'use strict';

    var $el = $( this ),
        valid = true,
        errors = {};

    return {
        valid: valid
    }

} );

$( document ).ready( function () {

    $( '[data-positive-number]' ).on( 'change', function () {
        if ( !$.isNumber( $( this ).val() ) ) {
            $( this ).val( '' );
        }

        if (parseInt($(this).val()) < 0) {
            $(this).val('');
        }
    } );

    $( '.phone-input' ).on( 'focusout', function () {
        var $el     = $( this );
        var isValid = $el.validatePhone();
        if ( $el.val() !== "" && !isValid ) {
            $el.addClass( 'error' );
        }
    } );

    $( '.email-input' ).on( 'focusout', function () {
        var $el = $( this );
        if ( !isValidEmailAddress( $el.val() ) ) {
            $el.addClass( 'error' );
        }
    } );
} );
