/**
 * Created by Mauricio.Ruiz on 21/10/2015.
 */
(function(app, $) {
    
    /* Factory */
    (function(factory) {
            'use strict';

            app.Modules.QuickQuoteModule = factory;

        }(function() {
            'use strict';

            // Const
            var url = Enodo.rootUrl + '/Home/GetQuotes';

            // Vars
            var $el,
                itemsTable,
                $clearTableButton = '[data-clear-table]',
                $locationForm = '[data-location-form]',
                $quoteButton = '[data-quote-button]',
                $quoteModal = '#quote-modal',
                totals = {
                    NoItems: 0,
                    Weight: 0,
                    Volume: 0,
                    dg: 0
                };

            var refreshTotals = function() {
                var items = itemsTable.getItems();

                totals = {
                    NoItems: 0,
                    Weight: 0,
                    Volume: 0,
                    dg: 0
                };
                items.forEach(function (val, index) {
                    console.log(index);
                    console.log(val);
                    totals.NoItems += val.NoItems;
                    totals.Weight += val.NoItems * val.Weight;
                    totals.Volume += val.NoItems * val.Volume;
                    totals.dg += val.dg;
                });

                setTotals(totals);
            };

            var setTotals = function (totals) {
                console.log(totals);
                if (Object.keys(totals).length === 0) {
                    $el.find('[data-total="items"]').html(0);
                    $el.find('[data-total="weight"]').html(0);
                    $el.find('[data-total="volume"]').html(0);
                    $el.find('[data-total="dgs"]').html('');
                } else {
                    $el.find('[data-total="items"]').html(totals.NoItems);
                    $el.find('[data-total="weight"]').html(totals.Weight.toFixed(3));
                    $el.find('[data-total="volume"]').html(totals.Volume.toFixed(3));
                    $el.find('[data-total="dgs"]').html(totals.dg > 1 ? 'DGs' : '');
                }
            };

            var getQuoteData = function() {
                var items = itemsTable.getItems(),
                    data;

                if (items.length === 0) {
                    $quoteButton
                        .attr('data-original-title', 'Please add some items to quote')
                        .tooltip('fixTitle');
                    $quoteButton.showTooltip();
                    return false;
                }

                data = {
                    isReceiverResidential: $locationForm.find("#receiver-residential").is(':checked'),
                    receiverLocationID: $locationForm.find('#receiver-location').data('locationId'),
                    isSenderResidential: $locationForm.find("#sender-residential").is(':checked'),
                    senderLocationID: $locationForm.find('#sender-location').data('locationId'),
                    todayDate: $.datepicker.formatDate('yy-mm-dd', new Date()),
                    isPOBox: $("#pobox").is(':checked'),
                    insuranceValue: $("#insuranceValue").val(),
                    conItems: JSON.stringify(items),
                    hasDGs: totals.dg > 0
                };

                if (!data.senderLocationID || !data.receiverLocationID) {
                    $quoteButton
                        .attr('data-original-title', 'Please add Sender Location and Receiver Location')
                        .tooltip('fixTitle');
                    $quoteButton.showTooltip();
                    return false;
                }

                return data;
            };

            var quote = function() {
                var data = getQuoteData();

                if (data) {
                    $.ajax({
                            url: url,
                            type: 'POST',
                            dataType: 'html',
                            data: data
                        })
                        .done(function(html) {
                            $quoteModal.find('[data-body]').html(html);
                            $quoteModal.modal('show');
                            $('[data-toggle-new-row]').trigger('click');
                        })
                        .fail(function() {
                            app.log(2, 'QuickQuoteModule, function GetQuotes. FAILED: failed server response');
                        });
                }
            };

            return {
                init: function(el) {
                    // DOM
                    $el = $(el);
                    itemsTable = new app.Modules.EnodoTableModule();
                    $locationForm = $el.find($locationForm);
                    $quoteButton = $el.find($quoteButton);
                    $clearTableButton = $el.find($clearTableButton);
                    $quoteModal = $el.find($quoteModal);

                    // Set up
                    $el.find('[data-autocomplete="code"]')
                        .codeAutocomplete(
                        {
                            name: '',
                            lenght: '[data-type="Length"]',
                            width: '[data-type="Width"]',
                            weight: '[data-type="Weight"]',
                            height: '[data-type="Height"]',
                            volume: '[data-type="Volume"]',
                            itemtype: '[data-type="ItemType"]',
                            productid: '[data-type="ProductID"]'

                        });

                    $el.find('#sender-location').locationAutocomplete();
                    $el.find('#receiver-location').locationAutocomplete();
                    $quoteButton.tooltip({
                        title: 'Please add some items to quote',
                        trigger: 'manual'
                    });

                    itemsTable.init(
                        $el.find('[data-itemTable]'),
                        {
                            responsive: true,
                            addRowData: function(dataType) {
                                return [
                                    { name: "Code", val: dataType.Code },
                                    { name: "NoItems", val: dataType.NoItems },
                                    { name: "Length", val: dataType.Length },
                                    { name: "Width", val: dataType.Width },
                                    { name: "Height", val: dataType.Height },
                                    { name: "Weight", val: dataType.Weight },
                                    {
                                        name: "Volume",
                                        val: parseFloat((dataType.Length * dataType.Height * dataType.Width) / 1000000).toFixed(3)
                                    },
                                    { name: "dg", val: dataType.dg, text: (dataType.dg === 1 ? 'Yes' : 'No') },
                                    '<a data-edit class="icon-icons-edit"></a>',
                                    '<a data-delete class="icon-icons-delete"></a>',
                                    { name: "ItemType", val: dataType.ItemType },
                                    { name: "ProductID", val: dataType.ProductID == '' ? 0 : dataType.ProductID }
                                ];
                            },
                            validation: function(row) {
                                return (!isNaN(row['NoItems']) &&
                                    row['NoItems'] !== 0 &&
                                    !isNaN(row['Length']) &&
                                    row['Length'] !== 0 &&
                                    !isNaN(row['Width']) &&
                                    row['Width'] !== 0 &&
                                    !isNaN(row['Height']) &&
                                    row['Height'] !== 0 &&
                                    !isNaN(row['Weight']) &&
                                    row['Weight'] !== 0);
                            },
                            onClose: function() {
                                $quoteButton.focus();
                            },
                            initComplete: function() {
                                $('[data-toggle-new-row]').trigger('click');
                            }
                        }
                    );

                    // Events
                    $clearTableButton.on('click',
                        function() {
                            itemsTable.cleanTable();
                            $locationForm.get(0).reset();
                            setTotals({
                                NoItems: 0,
                                Weight: 0,
                                Volume: 0,
                                dg: 0
                            });
                        });
                    itemsTable.$data.on('dataChange', refreshTotals);
                    $quoteButton.on('click', quote);
                }
            }
        })
    )
}(Enodo || window, jQuery));