var HomeReports = (function () {

    'use strict';

    var SERIES_COLOR  = [ '#43B54A', '#308235', '#AAAAAA' ],
        LABEL_SIZE    = 12,
        BAR_WIDTH     = 10,
        REPORT_HEIGHT = 260;

    /*
     ***************** STATE USAGE REPORT *****************
     **/
    var StateUsageReport = function () {
        var dataset = {},
            type    = "bar", // bar, pie
            dataSource,
            $el;

        var getDataSet = function () {
            var deferred = $.Deferred();

            $.ajax( {
                    url: Enodo.rootUrl + '/Home/GetStateUsage',
                    type: 'POST',
                    dataType: 'json',
                    data: {}
                } )
                .done ( function () {
                    deferred.resolve.apply( this, arguments );
                } )
                .fail( function () {
                    deferred.reject.apply( this, arguments );
                } );

            return deferred.promise();
        };

        var getDataSource = function () {
            dataset.addPercentage( [ 'Value' ] );
            return new DevExpress.data.DataSource( {
                store: dataset.get()
            } );
        };

        var drawPieChart = function ( $el ) {
            dataSource.sort( {
                getter: 'ValuePercentage',
                desc: true
            } );
            dataSource.load();

            $el.dxPieChart( {
                size: {
                    height: REPORT_HEIGHT
                },
                dataSource: dataSource,
                title: {
                    text: 'Shipping by Location',
                    font: { size: 20 }
                },
                series: {
                    argumentField: 'Display',
                    valueField: 'ValuePercentage'
                },
                legend: {
                    customizeText: function ( pointInfo ) {
                        var text = "";
                        dataset.get().forEach( function ( obj, index ) {
                            if ( obj.Display === pointInfo.pointName ) {
                                text = obj.ValuePercentage;
                            }
                        } );

                        return parseFloat( text * 100 ).formatPercentage() + '-' + pointInfo.pointName;
                    }
                }
            } );
        };

        var drawBarChart = function ( $el ) {
            dataSource.sort( {
                getter: 'ValuePercentage',
                desc: false
            } );
            dataSource.load();

            var chart = $el.dxChart( {
                size: {
                    height: REPORT_HEIGHT
                },
                equalBarWidth: {
                    width: BAR_WIDTH
                },
                rotated: true,
                dataSource: dataSource,
                valueAxis: {
                    label: {
                        visible: false
                    }
                },
                title: {
                    text: 'Shipping by Location',
                    font: { size: 20 }
                },
                series: [
                    {
                        argumentField: 'Display',
                        valueField: 'ValuePercentage',
                        type: "bar",
                        color: SERIES_COLOR[ 0 ],
                        label: {
                            visible: true,
                            backgroundColor: 'white',
                            format: "percent",
                            precision: 1,
                            font: {
                                color: SERIES_COLOR[1],
                                weight: 800
                            }
                        }
                    }
                ],
                legend: {
                    visible: false
                }
            } );
        };

        var drawChart = function ( $el ) {
            $el.empty();
            $el.removeData();

            if ( type === 'bar' ) {
                drawBarChart( $el );
            }
            else if ( type === 'pie' ) {
                drawPieChart( $el );
            }
            else {
                Enodo.log( 2, 'Chart Type not implemented' );
                drawBarChart( $el );
            }
        };

        return {
            render: function ( _el ) {
                $el = $( _el );

                getDataSet()
                    .done( function ( data ) {
                        dataset    = new DataSet( data );
                        dataSource = getDataSource( data );

                        drawChart( $el );
                    } )
                    .fail( function () {
                        Enodo.log( 2, ' HomeReports. FAILED: empty dataset ' );
                    } );
            },
            changeChartType: function ( _type ) {
                type = _type;
                drawChart( $el );
            }
        };
    };


    /*
     ***************** CARRIER USAGE REPORT *****************
     **/
    var CarrierUsageReport = function () {
        var dataset = {},
            type    = "bar", // bar, pie
            dataSource,
            $el;

        var getDataSet = function () {
            var deferred = $.Deferred();

            $.ajax( {
                    url: Enodo.rootUrl + '/Home/GetCarrierUsage',
                    type: 'POST',
                    dataType: 'json',
                    data: {}
                } )
                .done ( function () {
                    deferred.resolve.apply( this, arguments );
                } )
                .fail( function () {
                    deferred.reject.apply( this, arguments );
                } );

            return deferred.promise();
        };

        var getDataSource = function () {
            dataset.addPercentage( [ 'Value' ] );
            return new DevExpress.data.DataSource( {
                store: dataset.get()
            } );
        };

        var drawPieChart = function ( $el ) {
            dataSource.sort( {
                getter: 'ValuePercentage',
                desc: true
            } );
            dataSource.load();

            $el.dxPieChart( {
                size: {
                    height: REPORT_HEIGHT
                },
                dataSource: dataSource,
                title: {
                    text: 'Consignments by Carrier',
                    font: { size: 20 }
                },
                series: { argumentField: 'Display', valueField: 'ValuePercentage' },
                legend: {
                    customizeText: function ( pointInfo ) {
                        var text = "";
                        dataset.get().forEach( function ( obj, index ) {
                            if ( obj.Display === pointInfo.pointName ) {
                                text = obj.ValuePercentage;
                            }
                        } );

                        return parseFloat( text * 100 ).formatPercentage() + '-' + pointInfo.pointName.ellipsis( 35 );
                    }
                }
            } );
        };

        var drawChart = function ( $el ) {
            $el.empty();
            $el.removeData();

            if ( type === 'bar' ) {
                drawBarChart( $el );
            }
            else if ( type === 'pie' ) {
                drawPieChart( $el );
            }
            else {
                Enodo.log( 2, 'Chart Type not implemented' );
                drawBarChart( $el );
            }
        };

        var drawBarChart = function ( $el ) {
            dataSource.sort( {
                getter: 'ValuePercentage',
                desc: false
            } );
            dataSource.load();

            $el.dxChart( {
                size: {
                    height: REPORT_HEIGHT
                },
                equalBarWidth: {
                    width: BAR_WIDTH
                },
                rotated: true,
                dataSource: dataSource,
                valueAxis: {
                    label: {
                        visible: false
                    }
                },
                title: {
                    text: 'Consignments by Carrier',
                    font: { size: 20 }
                },
                series: [
                    {
                        argumentField: 'Display',
                        valueField: 'ValuePercentage',
                        type: "bar",
                        color: SERIES_COLOR[ 0 ],
                        label: {
                            visible: true,
                            backgroundColor: 'white',
                            format: "percent",
                            precision: 1,
                            font: {
                                size: LABEL_SIZE,
                                color: SERIES_COLOR[1],
                                weight: 800
                            }
                        }
                    }
                ],
                argumentAxis: {
                    label: {
                        customizeText: function () {
                            return this.value.ellipsis();
                        }
                    }
                },
                legend: {
                    visible: false
                }
            } );
        };

        return {
            render: function ( _el ) {
                $el = $( _el );

                getDataSet()
                    .done( function ( data ) {
                        dataset    = new DataSet( data );
                        dataSource = getDataSource( data );

                        drawChart( $el );
                    } )
                    .fail( function () {
                        Enodo.log( 2, ' HomeReports. FAILED: empty dataset ' );
                    } );
            },
            changeChartType: function ( _type ) {
                type = _type;
                drawChart( $el );
            }
        };
    };

    /*
     ***************** STATISTICS USAGE REPORT *****************
     **/
    var StatisticsReport = function () {
        var dataset = {},
            dataSource,
            $el;

        var getDataSet = function () {
            var deferred = $.Deferred();

            $.ajax( {
                    url: Enodo.rootUrl + '/Home/GetStatistics',
                    type: 'POST',
                    dataType: 'json',
                    data: {}
                } )
                .done ( function () {
                    deferred.resolve.apply( this, arguments );
                } )
                .fail( function () {
                    deferred.reject.apply( this, arguments );
                } );

            return deferred.promise();
        };

        var getDataSource = function () {
            dataset.process( function ( data ) {
                var res   = [],
                    title = 'Code',
                    cols  = [ 'LastMonth', 'LastWeek', 'ThisMonth', 'ThisWeek', 'Today' ];

                cols.forEach( function ( col, index ) {
                    var row = {};

                    row.Title = col;
                    [].forEach.call( data, function ( obj, index ) {
                        row[ obj[ title ] ] = obj[ col ];
                    } );

                    res.push( row );
                } );

                return res;
            } );

            return new DevExpress.data.DataSource( {
                store: dataset.get()
            } );
        };

        var drawChart = function ( $el ) {
            $el.dxChart( {
                size: {
                    height: REPORT_HEIGHT
                },
                dataSource: dataSource,
                commonSeriesSettings: {
                    argumentField: "Title",
                    type: "bar",
                    hoverMode: "allArgumentPoints",
                    axis: 'axis',
                },
                title: {
                    text: 'Statistics',
                    font: { size: 20 }
                },
                series: [
                    { valueField: 'Cons', name: 'Consignments', color: SERIES_COLOR[ 0 ] },
                    { valueField: 'Items', name: 'Items', color: SERIES_COLOR[ 1 ] },
                    {
                        valueField: 'Weight',
                        name: 'Weight', color: SERIES_COLOR[ 2 ],
                        axis: 'weightAxis'
                    }
                ],
                tooltip: {
                    enabled: true,
                    format: 'largeNumber'
                },
                valueAxis: [
                    { name: 'axis',
                        label: { format: 'largeNumber' },
                        title: {
                            text: 'Consignments / Items'
                        }
                    },
                    {
                        name: 'weightAxis',
                        position: 'right',
                        label: { format: 'largeNumber' },
                        title: {
                            text: 'Weight'
                        }
                    }
                ]
            } );
        };

        return {
            render: function ( _el ) {
                $el = $( _el );

                getDataSet()
                    .done( function ( data ) {
                        dataset    = new DataSet( data );
                        dataSource = getDataSource( data );

                        drawChart( $el );
                    } )
                    .fail( function () {
                        Enodo.log( 2, ' HomeReports. FAILED: empty dataset ' );
                    } );
            }
        };
    };

    return {
        StateUsageReport: StateUsageReport,
        CarrierUsageReport: CarrierUsageReport,
        StatisticsReport: StatisticsReport
    }

}());

Enodo.Repositories.HomeReports = HomeReports;