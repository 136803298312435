/*
 * LOCATION AUTOCOMPLETE MODULE
 * */
jQuery.widget("app.codeAutocomplete",
{
    options: {
        name: '* [rel="txtDescription"]',
        lenght: '* [rel="txtLength"]',
        width: '* [rel="txtWidth"]',
        weight: '* [rel="txtWeight"]',
        height: '* [rel="txtHeight"]',
        volume: 'td[rel="volume"]',
        totalVolume: 'td[rel="totalVolume"]',
        items: 'input[rel="txtItems"]'
    },
    _create: function() {
        var that = this;

        this.element.autocomplete({
            source: function(request, response) {
                $.ajax({
                    url: rootUrl + '/BookingRequest/SearchCodes/',
                    data: { searchValue: request.term },
                    dataType: "json",
                    success: function(data) {
                        response($.map(data,
                            function(code) {
                                return {
                                    id: code.ProductID,
                                    value: JSON.stringify(code),
                                    label: code.Code
                                };
                            }));
                    },
                    error: function(request, status, error) {
                        $.okDialog(error);
                    }
                });
            },
            focus: function(event, ui) {
                var codeobj = JSON.parse(ui.item.value);
                $(this).val(codeobj.Code);
                return false;
            },
            select: function(event, ui) {
                var codeobj = JSON.parse(ui.item.value);
                var currRow = $(this).parent().parent();

                $(this).val(codeobj.Code).trigger("change");
                $(this).attr("data-productid", ui.item.id);

                $(currRow).find(that.options.name).val(codeobj.Name);
                $(currRow).find(that.options.lenght).val(codeobj.Length);
                $(currRow).find(that.options.width).val(codeobj.Width);
                $(currRow).find(that.options.weight).val(codeobj.Weight);
                $(currRow).find(that.options.height).val(codeobj.Height);
                $(currRow).find(that.options.volume).val(codeobj.Volume.toFixed(3));
                $(currRow).find(that.options.itemtype).val(codeobj.Code );
                $(currRow).find(that.options.productid).val(ui.item.id);

                if (that.options.totalVolume) {
                    $(currRow).find(that.options.totalVolume).html(($(currRow).find(that.options.volume).html() * $(currRow).find(that.options.items).val()).toFixed(3));
                }

                return false;
            },
            minLength: 2,
            change: function(event, ui) {
                if (!ui.item) {
                    $(this).attr("data-productid", 0);
                }
            }
        });
    }
});