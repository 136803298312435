<template>
    <div class="form-group" :class="{'has-error': error}">
        <label v-if="label" class="control-label" :for="identifier">{{ label }}</label>
        <v-select placeholder="Search"
                  :key="selectKey"
                  label="id"
                  :options="options"
                  :reduce="option => option[optionKey]"
                  :components="{OpenIndicator: null}"
                  :selectOnTab="true"
                  :value="value"
                  :clearable="false"
                  :filterable="false"
                  @search="onSearch"
                  @input="$emit('input', $event)">
            <template slot="no-options">Type to search locations...</template>
            <template slot="option" slot-scope="option">
                <div class="">
                    <b>{{ option.locality }}</b>
                    <small>{{ option.state }}</small>
                    <small>{{ option.postcode }}</small>
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
                {{ option.locality }} {{ option.state }} {{ option.postcode }}
            </template>
        </v-select>
        <slot name="errors" v-if="error"></slot>
    </div>
</template>

<script>
  import { locationClient } from '../services/api-client-service'

  export default {
    name: 'OfLocationInput',
    data () {
      return {
        options: [],
        optionKey: 'id',
        selectKey: 0
      }
    },
    props: {
      value: {},
      readOnly: {type: Boolean, default: false},
      required: {type: Boolean, default: false},
      label: {type: String},
      error: {default: false, type: Boolean}
    },
    computed: {
      identifier () {
        return 'of_location-control'
      },
      tabIndex () {
        return this.readOnly ? -1 : 0
      }
    },
    watch: {
      value () {
        this.selectKey += 1
      }
    },
    methods: {
      onSearch (search, loading) {
        if (search.length >= 2) {
          loading(true)
          locationClient.search({search})
            .then((locations) => {
              this.options = locations
              loading(false)
            })
        }
        loading(false)
      }
    }
  }
</script>

<style scoped>

</style>