/**
 * Created by Mauricio.Ruiz on 27/10/2015.
 */
window.DataSet = function ( _dataset ) {
    var rawDataset = _dataset || [],
        dataset    = rawDataset;

    this.get = function get () {
        return dataset;
    };

    this.reset = function reset ( _dataset ) {
        dataset = _dataset || rawDataset;
    };

    this.process = function process ( fun ) {
        if ( typeof fun === 'function' ) {
            dataset = fun( dataset );
        }
        else {
            Enodo.log( 2, 'Dataset. FAILED: function process must receive a function' )
        }
    };

    this.getTotalSum = function getTotalSum ( columnName ) {
        if ( typeof columnName === 'string' ) {
            return dataset.reduce( function ( res, obj ) {
                return res + obj[ columnName ];
            }, 0 );
        }
        else {
            throw new Error( 'Column name is not valid' );
        }
    };

    this.groupBy = function groupBy ( groupBy, sum ) {
        var result = {};

        if ( typeof groupBy !== 'string' && !groupBy in dataset[ 0 ] ) {
            throw new Error( 'Group By column name is not valid' );
        }

        if ( typeof sum !== 'object' && sum.length === 0 ) {
            throw new Error( 'Sum array is not valid. Sum: Array["columnName"]' );
        }

        result = dataset.reduce( function ( res, obj ) {
            if ( obj[ groupBy ] in res ) {
                sum.forEach( function ( el, index ) {
                    res[ obj[ groupBy ] ][ el ] += obj[ el ];
                } )
            }
            else {
                res[ obj[ groupBy ] ] = $.extend( {}, obj )
            }
            return res;
        }, result );

        result = $.map( result, function ( value, index ) {
            return [ value ];
        }, [] );

        dataset = result;
    };

    this.addPercentage = function addPercentage ( columns ) {
        var result = [],
            total  = {};

        total = dataset.reduce( function ( tot, obj ) {
            columns.forEach( function ( column, index ) {
                if ( column in tot ) {
                    tot[ column ] += parseFloat( obj[ column ] );
                }
                else {
                    tot[ column ] = parseFloat( obj[ column ] );
                }
            } );
            return tot;
        }, total );

        result = $.map( dataset, function ( val, index ) {
            columns.forEach( function ( column ) {
                val[ column + 'Percentage' ] = val[ column ] / total[ column ];
            } );
            return [ val ];
        }, result );

        return result;
    };

    this.addColumn = function addColumn ( col1, operator, col2, name ) {
        return dataset.map( function ( row, index ) {
            if ( operator === "/" ) {
                row[ name ] = row[ col1 ] / row[ col2 ];
            }
            else if ( operator === "+" ) {
                row[ name ] = row[ col1 ] + row[ col2 ];
            }
            else if ( operator === "*" ) {
                row[ name ] = row[ col1 ] * row[ col2 ];
            }
            else {
                row[ name ] = row[ col1 ] - row[ col2 ];
            }
            return row;
        } );
    };

    this.addCustomColumn = function addCustomColumn ( closure ) {
        if ( typeof closure !== 'function' ) {
            throw new Error( 'Argument has to be a function' );
        }

        return dataset.map( function ( row, index ) {
            return closure( row );
        } );
    };
};