<template>
    <div class="input-table-wrapper">
        <div class="quick-quote__items input-table"
             ref="table"
             :style="inputTableStyles">
            <!-- headers -->
            <div v-for="(_column, _colIdx) in columns"
                 class="input-table__header"
                 :class="_column.customClass"
                 :style="{'grid-column': _colIdx + 1, 'grid-row': 1}"
                 v-html="_column.title">
            </div>
            <div class="is-borderless"
                 :style="{'grid-column': columns.length + 1, 'grid-row': 1}"><!-- delete --></div>

            <!-- body -->
            <template v-for="(_item, _index) in model.$each.$iter">
                <template v-for="(_column, _colIndex) in columns">
                    <div class="input-table__input"
                         :style="{'grid-column': _colIndex + 1, 'grid-row': _index + 2}"
                         :class="[_column.customClass, 
                                {'input-table__input--has-error': _item[_column.name] ? _item[_column.name].$error : false}]">
                        <component v-if="_column.component"
                                   :is="_column.component"
                                   :ref="'input-' + _column.name"
                                   :class="_column.customClass"
                                   v-bind="_column.attrs ? _column.attrs(_item.$model) : {}"
                                   v-on="_column.events ? _column.events(_item.$model) : {}"
                                   :rowData="_item.$model"></component>
                        <input v-else
                               :title="_column.title"
                               :class="_column.customClass"
                               :ref="'input-' + _column.name"
                               v-bind="_column.attrs ? _column.attrs : {}"
                               v-model="_item.$model[_column.name]">
                    </div>
                </template>
                <button title="Remove Item" type="button" class="input-table__input__remove btn-zero"
                        :style="{'grid-column': columns.length + 1, 'grid-row': _index + 2}"
                        @click="removeItem(_index)">
                    <span class="icon-icons-delete"></span></button>
            </template>
        </div>
        <div class="input-table__footer">
            <div class="input-table__totals">
                
            </div>
            <button type="button"
                    class="input-table__add-item btn btn-zero btn-sm"
                    @click="addItem({focus: true})">+ Add Item
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'InputTable',
    props: ['columns', 'items', 'model'],
    computed: {
      inputTableStyles () {
        return {
          'grid-template-columns': this.columns.reduce((current, value) => {
            return current + (value.width ? value.width + ' ' : '1fr ')
          }, '') + '40px',
          '-ms-grid-columns': this.columns.reduce((current, value) => {
            return current + (value.width ? value.width + ' ' : '1fr ')
          }, '') + '40px',
        }
      }
    },

    created () {
      this.addItem()
    },
    
    mounted () {
      if (this.$refs.table) {
        this.$refs.table.style['-ms-grid-columns'] = this.columns.reduce((current, value) => {
          return current + (value.width ? value.width + ' ' : '1fr ')
        }, '') + '40px'
      }
    },

    methods: {
      addItem ({focus = false} = {}) {
        const firstInput = 'input-' + this.columns[0].name

        if (this.items.length !== 0) {
          this.model.$touch()
        }

        if (this.model.$invalid && this.items.length !== 0) {
          this.$refs[firstInput][this.$refs[firstInput].length - 1].$el.querySelector('input').focus()
          return
        }

        if (this.items.length == 0 || !this.model.$invalid) {
          this.items.push(this.columns.reduce((current, col) => {
            current[col.name] = null

            if (col.default) {
              current[col.name] = col.default
            }

            return current
          }, {}))

          if (focus) {

            this.$nextTick(() => {
              this.$refs[firstInput][this.$refs[firstInput].length - 1].$el.querySelector('input').focus()
            })
          }
        }
      },

      removeItem (index) {
        this.items.splice(index, 1)
      }
    }
  }
</script>

<style lang="scss" scoped>
    .input-table-wrapper {
        width: 100%;
    }

    .input-table {
        width: 100%;
        display: -ms-grid;
        display: grid;
        grid-gap: 0;
        margin-bottom: 0.5em;

        > * {
            width: 100%;
            height: 100%;
            min-height: 30px;
            border: none;
            border-left: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            padding: 3px;
        }

        > .is-borderless {
            border: none;
        }

    .is-readonly {
        background-color: #f2f2f2;
        pointer-events: none;
    }

        > .input-table__header {
            font-weight: bold;
            align-self: center;
            font-size: 0.925em;
            padding: 5px;
            border: none;
            border-bottom: 2px solid #e0e0e0;
            border-left: none;
        }

        > div.input-table__input {
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &.input-table__input--has-error,
            &.input-table__input--has-error {
                border: 1px solid #ce4844;
            }

            > input[type=checkbox],
            > input[type=radio] {

            }

            > *:not([type=checkbox]):not([type=radio]) {
                height: 100%;
                width: 100%;
                border: none;
            }
        }

        .input-table__input__remove {
            border: none;
            border-left: 1px solid #e0e0e0;
        }

        .form-group {
            padding: 0;
            margin: 0;
            height: 100%;

            > * {
                height: 100%;
            }
        }

        .vs__dropdown-toggle {
            height: 100%;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            border: none;
            padding: 0;
        }
    }

    .input-table__footer {
        margin-right: 30px;
        display: flex;
        justify-content: space-between;

        > * {
            /*flex: 1;*/
        }
    }
</style>

<!-- Non scoped style -->
<style>
    .input-table .v-select .vs__dropdown-toggle {
        height: 100%;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: none;
        padding: 0;
    }
</style>