export default function saveProduct (product) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: '/products',
      dataType: 'json',
      cache: false,
      data: {
        product: JSON.stringify(product)
      },
      success: () => {
        resolve()
      },
      error: function (request, status, error) {
        reject(request, status, error)
      }
    })
  })
} 