import Vue from 'vue'

import OfPanel from './components/OFPanel.vue'
import MyProducts from './modules/my-products/pages/MyProducts'
import OFFormInput from './components/OFFormInput'
import OFFormCheckbox from './components/OFFormCheckbox'
import Vuelidate from 'vuelidate'
import QuickQuote from './modules/quoting/components/QuickQuote'
import ShippingUnitsGrid from './modules/consignment/components/ShippingUnitsGrid'
import ShippingUnitsSummary from './modules/consignment/components/ShippingUnitsSummary'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css'

// plugins
Vue.use(Vuelidate)

// components
Vue.component("v-select", vSelect);

Vue.component('OfPanel', OfPanel)
Vue.component('OFFormInput', OFFormInput)
Vue.component('OFFormCheckbox', OFFormCheckbox)

// views
Vue.component('ShippingUnitsGrid', ShippingUnitsGrid)
Vue.component('ShippingUnitsSummary', ShippingUnitsSummary)

/* event bus definition. This is used for outer communication with legacy js code */
window.eventBus = new Vue();

window.vueApp = new Vue({
  el: '#app.vue-app',
  components: {
    MyProducts,
    QuickQuote
  }
})