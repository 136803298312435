/**
 * Created by Mauricio.Ruiz on 22/10/2015.
 */

( function ( app ) {

    /* Factory */
    (function ( factory ) {
            'use strict';

            app.Modules.EnodoTableModule = factory;

        }( function () {
            'use strict';

            var config = {
                // Save with enter and close footer row with esc
                quickControls: true,
                // Row representation in the table. This field is mandatory as it generated the necessary html for datatables.
                // This information can be added in two forms:
                //    - OBJ: { name: ColName, val: CellValue, CustomData: DataValue }
                //          ex:  { name: "item", val: row.item, productId: $row.find( 'input[data-type="item"]' ).data( 'productId' ) }
                //    - HTML: the html will be wrapped between <td></td>
                //
                // object row          RowData to be added
                // object $row         jQuery object of the row
                addRowData: function ( row, $row ) {}, // Mandatory
                // Validation before save (Optional)
                // object row          RowData to be added
                validation: function ( row ) { return true; },
                // save success callback (Optional)
                saveSuccess: function () {},
                // Edit complete callback (Optional)
                // object rowData      Row Data just added to the row
                editComplete: function ( rowData ) {},
                // Edit complete callback (Optional)
                // object rowData      Row Data just added to the row
                onDelete: function () {},
                // init complete callback (Optional)
                initComplete: function () {},
                // On Close event
                onClose: function () {}
            };

            /* DOM */
            var $table,
                dataTable,
                $body,
                $newRow,
                $toggleNewRowButton,
                $addRowButton,
                data = $( {} );

            // UTILS
            var renderRow = function ( rowdata ) {
                var text = "",
                    data = getData( $.extend( {}, rowdata ) );

                if ( typeof rowdata == 'object' ) {
                    rowdata.text = rowdata.text || rowdata.val || '';

                    if (rowdata.name === 'ItemType' || rowdata.name === 'ProductID')
                        text += "<span style=\"display:none\" data-type=\"" + rowdata.name + "\" data-val=\"" + rowdata.val + "\" " + data + " >" + rowdata.text + "</span>"
                    else
                        text += "<span data-type=\"" + rowdata.name + "\" data-val=\"" + rowdata.val + "\" " + data + " >" + rowdata.text + "</span>"

                }
                else {
                    text += rowdata;
                }
                return "<td>" + text + "</td>";
                
            };

            var getData = function ( rowdata ) {
                var html = "";

                delete rowdata.name;
                delete rowdata.val;
                delete rowdata.text;

                for ( var data in rowdata ) {
                    html += 'data-' + data + '="' + rowdata[ data ] + '"';
                }

                return html;
            };

            var renderRows = function ( rowsData ) {
                return rowsData.reduce( function ( res, obj, index ) {
                    return res.concat( renderRow( obj ) );
                }, [] )
            };

            var loopRows = function ( fun ) {
                if ( typeof fun === 'function' ) {
                    dataTable.rows().every( fun );
                }
                else {
                    app.log( 1, 'Module: "Enodo Table". FAILED: LoopRows fun is invalid' );
                }
            };

            var loopRow = function ( $tr, fun ) {
                if ( typeof fun === 'function' ) {
                    [].forEach.call( $tr.find( '[data-type]' ), fun );
                }
                else {
                    app.log( 1, 'Module: "Enodo Table". FAILED: LoopRow fun is invalid' );
                }
            };

            var getRowData = function ( $tr ) {
                var rowData = {};

                loopRow( $tr, function ( el ) {
                    var $el = $( el ),
                        val;

                    if ( $el.tagName() === 'INPUT' ) {
                        if ( $el.attr( 'type' ) === "number" ) {
                            val = parseFloat( $el.val() )
                        }
                        else if ( $el.attr( 'type' ) === "checkbox" ) {
                            val = $el.is( ':checked' ) ? 1 : 0;
                        }
                        else if ( $el.attr( 'type' ) === "text" || $el.attr( 'type' ) === "hidden" ) {
                            val = $el.val();
                        }
                    }
                    else if ($el.tagName() === 'SPAN') {
                        val = $el.data( 'val' );
                    }
                    else if ($el.tagName() === 'SELECT') {
                        val = $el.find(":selected").text();
                    }

                    rowData[ $el.data( 'type' ) ] = val;
                } );

                return rowData
            };

            //region METHODS

            var createTable = function () {
                dataTable = $table.DataTable( {
                    "length": parseInt( '@ViewBag.GridRecords' ),
                    "sort": false,
                    "searching": false,
                    "paginate": false,
                    "info": false,
                    "language": {
                        "sEmptyTable": "Please add some items to start"
                    }
                } );
            };

            var toggleNewRow = function () {
                $newRow.toggle( 500 );
                $toggleNewRowButton.toggle();
                selectFirstInput();
            };

            var openNewRow = function () {
                $newRow.show( 500 );
                $toggleNewRowButton.removeClass( 'glyphicon-plus' ).addClass( 'glyphicon-minus' );
            };

            var cleanNewRow = function () {
                loopRow( $newRow, function ( el ) {
                    var $el = $( el );
                    if ($el.tagName() === 'INPUT') {
                        if ($el.attr('type') === "checkbox") {
                            $el.prop('checked', false);
                        }
                        else {
                            $el.val($el.data('defaultvalue') || '');
                            $el.trigger('change');
                        }
                    } else if ($el.tagName() === 'SELECT') {
                        $el.val($el.data('defaultvalue') || '');
                        $el.trigger('change');
                    }
                } );
            };

            var selectFirstInput = function () {
                $newRow.find( 'input' ).get( 0 ).focus();
            };

            var saveRowAndClose = function () {
                var rowData = getRowData( $newRow );

                if ( validateRow( $newRow ) ) {
                    addRow( rowData, $newRow );
                    toggleNewRow();
                    data.trigger( 'dataChange' );
                    config.saveSuccess();
                }
                else {
                    $addRowButton.showTooltip();
                }
            };

            var saveRow = function () {
                var rowData = getRowData( $newRow );

                if ( validateRow( $newRow ) ) {
                    addRow( rowData, $newRow );
                    cleanNewRow();
                    selectFirstInput();
                    data.trigger( 'dataChange' );
                    config.saveSuccess();
                }
                else {
                    $addRowButton.showTooltip();
                }
            };

            var addRow = function ( rowData, $row ) {
                rowData = config.addRowData( rowData, $row || $( {} ) );
                dataTable.row.add( renderRows( rowData, $row ) ).draw();

                return $table.promise();
            };

            var validateRow = function ( $row ) {
                if ( !validateRequired( $row ) ) {
                    return false;
                }

                if ( typeof config.validation === 'function' ) {
                    return config.validation( getRowData( $row ) );
                }
                else {
                    Enodo.log( 2, 'Please add a validation function. config.validation' );
                }

                return false;
            };

            var validateRequired = function ( $row ) {
                var res = true;

                loopRow( $row, function ( el ) {
                    var $el = $( el );

                    if ( typeof $el.data( 'required' ) !== 'undefined' ) {
                        if ( $el.val() == 0 ) {
                            $el.addClass( 'error' );
                            res = false;
                        }
                    }
                } );

                $row.find( '.error' ).first().val( '' ).focus();

                return res;
            };

            var setInputData = function ( rowData ) {
                loopRow( $newRow, function ( el ) {
                    var $el = $( el );

                    if ($el.tagName() === 'INPUT') {
                        if ($el.attr('type') === "checkbox") {
                            $el.prop("checked", rowData[$el.data('type')] == 1);
                        }
                        else {
                            $el.val(rowData[$el.data('type')]);
                        }
                    } else if ($el.tagName() === 'SELECT') {
                        $el.find("option:contains(" + rowData[$el.data('type')] + ")").attr('selected', 'selected').change();
                    }
                } );

                openNewRow();
            };

            var editRow = function () {
                var rowData      = getRowData( $( this ).closest( 'tr' ) ),
                    inputRowData = getRowData( $newRow );

                var isEmpty = true;
                for ( var cellName in inputRowData ) {
                    if ( cellName !== 'txtItems' && cellName !== 'items' ) { // Temporal FIX
                        isEmpty &= inputRowData[ cellName ] === "" || inputRowData[ cellName ] === 0 || isNaN( inputRowData[ cellName ] );
                    }
                }

                if ( isEmpty ) {
                    setInputData( rowData );
                    deleteRow.call( this );
                    selectFirstInput();
                    config.editComplete( rowData );
                }
                else {
                    console.error( 'row not empty' );
                }
            };

            var deleteRow = function () {
                var row = $( this ).closest( 'tr' ).get( 0 );

                dataTable.row( row ).remove().draw( false );
                data.trigger( 'dataChange' );
                config.onDelete();
            };

            //endregion

            return {
                $data: data,
                init: function ( _el, _config ) {
                    $table = $( _el );
                    if ( config ) {
                        config = $.extend( config, _config );
                    }

                    $body               = $table.find( 'tbody' );
                    $toggleNewRowButton = $table.find( '[data-toggle-new-row]' );
                    $newRow             = $table.find( '[data-new-row]' );
                    $addRowButton       = $newRow.find( '[data-add-row]' );

                    createTable();
                    cleanNewRow();
                    $addRowButton.tooltip( {
                        title: 'Please fill all required spaces',
                        trigger: 'manual'
                    } );

                    $toggleNewRowButton.on( 'click', toggleNewRow );
                    $newRow.on( 'click', '[data-clean]', cleanNewRow );
                    if ( config.quickControls ) {
                        $newRow.on( 'keyup', '[data-type][type!=button]', function ( ev ) {
                            if ( ev.keyCode === 13 ) {
                                saveRow()
                            }
                            else if ( ev.keyCode === 27 ) {
                                toggleNewRow();
                                config.onClose();
                            }
                        } );
                    }
                    $addRowButton.on( 'click', saveRow );
                    $body.on( 'click', '[data-edit]', editRow );
                    $body.on( 'click', '[data-delete]', deleteRow );

                    config.initComplete();
                },
                getTotals: function () {
                    var response = {};

                    loopRows( function ( _el ) {
                        var rowData = getRowData( $( this.nodes() ) );

                        for ( var el in rowData ) {
                            if ( rowData.hasOwnProperty( el ) ) {
                                if ( response.hasOwnProperty( el ) ) {
                                    response[ el ] += parseFloat( rowData[ el ] );
                                }
                                else {
                                    response[ el ] = parseFloat( rowData[ el ] );
                                }
                            }
                        }
                    } );

                    return response;
                },
                getItems: function () {
                    var response = [];

                    loopRows( function ( el ) {
                        response.push( getRowData( $( this.nodes() ) ) );
                    } );

                    if ( response.length === 0 ) {
                        app.log( 2, 'No items added' );
                    }

                    return response;
                },
                cleanTable: function () {
                    dataTable.clear().draw( false );
                    cleanNewRow();
                },
                addItem: function ( rowData ) {
                    var defer = $.Deferred();
                    addRow( rowData ).then( defer.resolve, defer.reject );
                    return defer.promise();
                }
            };
        } )
    );

}( Enodo || window ));