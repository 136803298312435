require('./homeReports');

( function ( app, $ ) {

    /* Factory */
    (function ( factory ) {
            'use strict';

            app.Modules.HomeReportsModule = factory;

        }( function () {
            'use strict';

            var $el,
                stateUsageReport,
                carrierUsageReport,
                statisticsReport,
                repository       = Enodo.Repositories.HomeReports,
                $chartTypesPanel = '[data-chart-types-panel]';

            return {
                init: function ( el ) {
                    $el              = $( el );
                    $chartTypesPanel = $el.find( $chartTypesPanel );

                    // Init
                    stateUsageReport = new repository.StateUsageReport();
                    stateUsageReport.render( '[data-state-usage]' );

                    carrierUsageReport = new repository.CarrierUsageReport();
                    carrierUsageReport.render( '[data-carrier-usage]' );

                    statisticsReport = new repository.StatisticsReport();
                    statisticsReport.render( '[data-statistics]' );

                    // Events
                    $chartTypesPanel.on( 'click', '[data-chart-type]', function () {
                        stateUsageReport.changeChartType( $( this ).data( 'chart-type' ) );
                        carrierUsageReport.changeChartType( $( this ).data( 'chart-type' ) );
                    } );
                }
            }
        } )
    )
}( Enodo || window, jQuery ));

