<template>
    <div class="panel panel-default panel-enodo selected">
        <div class="panel-heading text-uppercase">
            {{ title }}
        </div>
        <div class="panel-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'OFPanel',
    props: {
      title: String
    }
  }
</script>

<style scoped>

</style>