import bluebird from 'bluebird'
import './bootstrap'

window.Promise = bluebird
window.$ = window.jQuery = require('jquery')

window.Handlebars = require('handlebars')

require('./vendor/jquery-ui.min')

require('metismenu')
require('moment')
window.moment = require('moment')
require('sweetalert')
require('bootstrap-sass/assets/javascripts/bootstrap.js')

require('./vendor/daterangepicker')
require('./vendor/jquery-migrate.min')
require('./vendor/jquery.form.min')
require('./vendor/jquery.form-validator')

require('datatables.net-bs')
require('datatables.net-buttons-bs')
require('datatables.net-buttons/js/buttons.html5.js')
require('datatables.net-select-bs')

require('./vendor/validation.js')
require('./vendor/jquery.dataTables.rowGrouping')
require('./vendor/sorting.currency.js')
require('./vendor/jquery.validate.js')
require('./vendor/jquery.unobtrusive-ajax.js')
require('./vendor/jquery.timepicker.js')
require('./vendor/jquery.cookie.js')
require('./vendor/jquery.number.js')
require('./vendor/jquery.plugin.clipboard.js')
require('./vendor/jquery.jqprint-0.3.js')
require('./vendor/select2.min')

require('./common/polyfills')
