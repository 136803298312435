<template>
    <v-select class="of-code-input"
              placeholder="Search"
              label="code"
              :value="value"
              :filterable="false"
              :options="codeOptions"
              :components="{OpenIndicator: null}"
              :selectOnTab="true"
              taggable
              :clearable="false"
              @search="onCodeSearch"
              @input="$emit('input', $event)">
        <template slot="no-options">Type to search codes...</template>
        <template slot="option" slot-scope="option">
            <div class="">
                <b>{{ option.code }}</b>
                <small v-if="option.name">{{ option.name }}</small>
                <small v-if="option.length">| {{ option.length }} x {{ option.width }} x {{ option.height }} cm</small>
                <small v-if="option.name">| {{ option.weight }} kg</small>
            </div>
        </template>
    </v-select>
</template>

<script>
  import { searchCodes } from '../services/api-client-service'
  import _ from 'lodash'

  export default {
    name: 'OfCodeInput',
    data () {
      return {
        codeOptions: []
      }
    },
    props: ['events', 'value'],

    methods: {
      onCodeSearch: _.debounce(function (search, loading) {
        if (search.length >= 2) {
          loading(true)
          searchCodes(search)
            .then((codes) => {
              this.codeOptions = codes
              loading(false)
            })
        }
        loading(false)
      }, 350)
    }
  }
</script>

<style scoped>
    .of-code-input {
        margin: 0;
        padding: 0;
    }
</style>